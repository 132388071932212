import { Box } from '@mui/material';
import { CopyBold } from '../TypographyFonts';
import { FC, useContext, useEffect, useState } from 'react';
import { UIContext } from 'UIProvider';

import { keyframes } from '@emotion/react';

const slideDown = keyframes`
  from { transform: translateY(0); }
  to { transform: translateY(100%); }
`;

interface CopySuccessMessageProps {
  onHide?: () => void;
}

const CopySuccessMessage: FC<CopySuccessMessageProps> = ({ onHide }) => {
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const { isMobile } = useContext(UIContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldDisplay(false);
    //   onHide?.();
    }, 4000); // Delay here is changed to 4 seconds to account for the 2 seconds of animation delay.
    return () => clearTimeout(timer);
  }, [onHide]);

  if (!shouldDisplay) {
    return null;
  }
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        right: '0px',
        height: isMobile ? '90px' : '100px',
        backgroundColor: '#E4F2DF',
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: 'slide-out 0.5s forwards',
        animationDelay: '3s', // Animation delay is added here
        '@keyframes slide-out': {
          '0%': {
            transform: 'translateY(0)',
          },
          '100%': {
            transform: 'translateY(100%)',
          },
        },

      }}
    >
      <CopyBold>😃 Copied to clipboard</CopyBold>
    </Box>
  );
};

export default CopySuccessMessage;

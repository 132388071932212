import { FC, useEffect, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { auth, storage } from '../../../Firebase';
import defaultAvatar from '../assets/avatar-default.png';
import { Box } from '@mui/material';
import userProfile from '../../../assets/svg/user-profile.svg';
import { getUser } from 'API';

interface UserProfileImageProps {
  size?: number;
  imageStoragePath?: string;
  userEmail?: string;
}

const UserContactImage: FC<UserProfileImageProps> = ({ size = 40, imageStoragePath, userEmail }) => {
  const [imageURL, setImageURL] = useState<string>();

  const getImageURL = async (path: string) => {
    try {
      const downloadURL = await getDownloadURL(ref(storage, path));
      setImageURL(downloadURL);
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    if (userEmail) {
      const fetchData = async () => {
        const response = await getUser(userEmail);
        const path = `user_images/${response?.uid}/${response?.uid}.jpg`;
        getImageURL(path);
      };
      fetchData();
    }
  }, [userEmail]);

  return (
    <Box
      sx={{
        width: '90px',
        minWidth: '90px',
        height: '90px',
        borderRadius: '24px',
        backgroundImage: `url(${imageURL || userProfile})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};

export default UserContactImage;

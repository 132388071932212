// MUI
import { Stack } from '@mui/material';
import { UIContext } from 'UIProvider';
import { FC, useContext } from 'react';
import { Kicker, Quote } from '../components/TypographyFonts';
import { ShowcaseApp, ShowcaseFeatureQuote } from 'ifaces';

interface ShowcaseFeatureQuoteSectionProps {
  projectFeatureQuote: ShowcaseFeatureQuote;
}

const ShowcaseFeatureQuoteSection: FC<ShowcaseFeatureQuoteSectionProps> = ({ projectFeatureQuote }) => {
  const { isMobile } = useContext(UIContext);
  return (
    <Stack
      sx={{
        py: 8,
        px: isMobile ? 4 : 8,
        margin: 'auto',
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{
          maxWidth: isMobile ? '311px' : '996px',
        }}
      >
        <Kicker isMobile={isMobile} sx={{ textAlign: 'center' }}>
          {projectFeatureQuote.title}
        </Kicker>
        <Quote sx={{ textAlign: 'center', mt: 1 }}>{projectFeatureQuote.description}</Quote>
      </Stack>
    </Stack>
  );
};

export default ShowcaseFeatureQuoteSection;

import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import './App.css';

// Auth Context
import { AuthCtx } from './AuthProvider';

// Custom Components
import LoadingPage from './pages/LoadingPage';
import UnauthorizedPage from './pages/UnauthorizedPage';

export interface Activity {
  type: string;
  code: string;
}

export interface ItemMatrix {
  code: string;
  type: string;
  item: number[];
}

export interface SecureLayoutProps {
  title?: string;
  adminRequired?: boolean;
  permissionRequired?: boolean;
  permissionName?: string;
}

function SecureLayout({
  children,
  adminRequired,
  permissionRequired,
  permissionName,
}: { children: JSX.Element } & SecureLayoutProps) {
  const { user, isAdmin, loading: loadingUser, permissions } = useContext(AuthCtx);

  let location = useLocation();

  if (loadingUser) {
    return <LoadingPage />;
  } else if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (adminRequired && !isAdmin) {
    return <UnauthorizedPage />;
  }

  if (permissionRequired && permissionName && permissions.indexOf(permissionName) === -1) {
    return <UnauthorizedPage />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default SecureLayout;

// MUI
import { Stack } from '@mui/material';
import { Copy, Header1, Header3 } from '../components/TypographyFonts';
import BlurredBannerBackground from '../components/BlurredBannerBackground/BlurredBannerBackground';
import useWindowSize from 'components/hooks/useWindowSize';
import { FC } from 'react';

export interface ShowcaseWeAreSectionProps {}

const ShowcaseWeAreSectionData = [
  {
    name: 'Expertise',
    description:
      'Berylls Digital Ventures is a digital product development and venturing firm. We are business product owners, data scientists, full-stack developers, software architects and venture builders. ',
  },
  {
    name: 'Customer driven innovation',
    description:
      'We exist because software-driven products, electrification, and integration with customer ecosystems all put the traditional automotive domain under unprecedented pressure to change.',
  },
  {
    name: 'From strategy to product',
    description:
      'We help our clients in the automotive and mobility industry turn ideas and strategies into real-world ventures. And we put skin in the game too. Berylls Digital Ventures is the early-stage investment arm of Berylls, where we focus on great ideas reaching their full potential.',
  },
];

const ShowcaseWeAreSection: FC<ShowcaseWeAreSectionProps> = ({}) => {
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;
  return (
    <Stack
      sx={{
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: isMobile ? '40px' : '120px',
        paddingBottom: isMobile ? '40px' : '120px',
        paddingLeft: isMobile ? 2 : 2,
        paddingRight: isMobile ? 2 : 2,
      }}
    >
      <Stack
        sx={{
          maxWidth: isMobile ? 'unset' : '690px',
          zIndex: 2,
        }}
      >
        <Header1
          isMobile={isMobile}
          sx={{
            '&.smoothed': {
              '-webkit-font-smoothing': 'antialiased',
              '-moz-osx-font-smoothing': 'grayscale',
            },
          }}
        >
          We are Berylls Digital Ventures
        </Header1>
        {ShowcaseWeAreSectionData.map((item, index) => {
          return (
            <>
              <Header3 isMobile={isMobile} sx={{ marginTop: '30px', marginBottom: 2 }}>
                {item.name}
              </Header3>
              <Copy isMobile={isMobile}>{item.description}</Copy>
            </>
          );
        })}
      </Stack>
      <BlurredBannerBackground />
    </Stack>
  );
};

export default ShowcaseWeAreSection;

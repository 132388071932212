import { ReactNode, useContext, useEffect, useState } from 'react';
// MUI
import ShowcaseAccordionImageVideoSection from '../ShowcasePage/ShowcaseAccordionImageVideoSection/ShowcaseAccordionImageVideoSection';
import ShowcaseFeatureOverviewSection from '../ShowcasePage/ShowcaseFeatureOverviewSection/ShowcaseFeatureOverviewSection';
import ShowcaseFeatureDescriptionSection from '../ShowcasePage/ShowcaseFeatureDescriptionSection/ShowcaseFeatureDescriptionSection';
import ShowcaseFeatureQuoteSection from '../ShowcasePage/ShowcaseFeatureQuoteSection/ShowcaseFeatureQuoteSection';
import ShowcaseFooterGlobalSection from '../ShowcasePage/ShowcaseFooterGlobalSection/ShowcaseFooterGlobalSection';
import ShowcaseQuoteSection from '../ShowcasePage/ShowcaseQuoteSection/ShowcaseQuoteSection';
import ShowcaseTextBlockSection from '../ShowcasePage/ShowcaseTextBlockSection/ShowcaseTextBlockSection';
import ShowcaseTextImageAnimationSection from '../ShowcasePage/ShowcaseTextImageAnimationSection/ShowcaseTextImageAnimationSection';
import ShowcaseTextImageBlockSection from '../ShowcasePage/ShowcaseTextImageBlockSection/ShowcaseTextImageBlockSection';
import ShowcaseVideoOrImageSection from '../ShowcasePage/ShowcaseVideoOrImageSection/ShowcaseVideoOrImageSection';
import MainLayout from '../ShowcasePage/components/MainLayout';
import ShowcaseDemoAndContactSection from '../ShowcasePage/ShowcaseDemoAndContactSection/ShowcaseDemoAndContactSection';
import ShowcaseHeaderSection from '../ShowcasePage/ShowcaseHeaderSection/ShowcaseHeaderSection';
import ShowcaseFooterNextProjectSection from 'pages/ShowcasePage/ShowcaseFooterNextProjectSection/ShowcaseFooterNextProjectSection';
import { useParams } from 'react-router-dom';
import { getShowcaseProject, getShowcaseProjects, postInvitedUser, checkUserId } from 'API';
import {
  ShowcaseAccordion,
  ShowcaseAnimation,
  ShowcaseContact,
  ShowcaseFeatureOverview,
  ShowcaseFeatureQuote,
  ShowcaseHeader,
  ShowcaseInDepthDescription,
  ShowcaseProject,
  ShowcaseQuote,
  ShowcaseTextBlock,
  ShowcaseTextImage,
  ShowcaseVideoOrImageWithFeatures,
} from 'ifaces';
import NotFoundPage from 'pages/NotFoundPage';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import ShowcaseNavigationBar from 'pages/ShowcasePage/components/ShowcaseNavigationBar/ShowcaseNagivationBar';
import { ShowcaseUserPageViewType } from 'ifaces';

// Sample Project asset imports
import React from 'react';
import { UIContext } from 'UIProvider';
import { ColorSet, adjustHSL, adjustHsbValue, hexToHSL, hexToRgb, hsbToHex, hslToHex, parseHSL, rgbToHsb } from 'utils';
import { BYDataCtx } from 'DataProvider';
import ShowcaseFixedNavigationBar from 'pages/ShowcasePage/components/ShowcaseFixedNavigationBar/ShowcaseFixedNavigationBar';
import { auth } from '../../Firebase';
import ShowcaseShareDialogBox from 'pages/ShowcasePage/components/ShareDialog/ShowcaseShareDialogBox';
import { Grid, Skeleton, Stack, useTheme } from '@mui/material';

const ShowcaseProjectOverviewPage = () => {
  // Loading State
  const [errorStatusCode, setErrorStatusCode] = useState<number>();
  const { projectColors, setProjectColors } = useContext(UIContext);

  // Get Project ID from URL
  const { project_id } = useParams<{ project_id: string }>();
  const [project, setProject] = useState<ShowcaseProject | undefined>(undefined);
  const [nextProject, setNextProject] = useState<ShowcaseProject | undefined>(undefined);

  const [showcaseShareDialogBoxOpen, setShowcaseShareDialogBoxOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const { setShowcaseUserPageView } = useContext(BYDataCtx);

  const { isMobile } = useContext(UIContext);

  const theme = useTheme();

  const handleError = (error: unknown) => {
    // Check if the error has a status code of 404
    if (error instanceof Error && error.message.includes('404')) {
      setErrorStatusCode(404);
    }
    // Check if the error has a status code of 403
    if (error instanceof Error && error.message.includes('403')) {
      setErrorStatusCode(403);
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        if (!project_id) return;
        const project = await getShowcaseProject(project_id);
        setProject(project);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        if (auth.currentUser === null) return;
        const responseStatus = await checkUserId(auth.currentUser?.uid);
        if (responseStatus === 200) {
          setShowcaseUserPageView(ShowcaseUserPageViewType.ADMIN_DETAIL_VIEW);
        } else {
          setShowcaseUserPageView(ShowcaseUserPageViewType.CLIENT_DETAIL_VIEW);
        }
      } catch (error) {
        handleError(error);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    fetchProject();
  }, [project_id]);

  useEffect(() => {
    const fetchNextProject = async () => {
      try {
        const projects = await getShowcaseProjects();
        // find the array index of the current project
        const projectIndex = projects.findIndex(project => project.project_id === project_id);
        // find projectindex +1 in the projects
        const nextProject = projects[projectIndex + 1];
        setNextProject(nextProject);
      } catch (error) {
        console.log(error);
      }
    };
    fetchNextProject();
  }, [project_id]);

  // Set Project Colors
  useEffect(() => {
    if (project) {
      const setColors = async () => {
        try {
          if (project.settings?.project_color !== undefined) {
            // HEX TO HSL - To find accent, accent light, accent dark, and icon colors
            const hslColor = hexToHSL(project.settings?.project_color);
            const parsedHSL = parseHSL(hslColor);
            if (parsedHSL !== null) {
              const colors: ColorSet = adjustHSL(parsedHSL);
              // set the project colors in the format of HEX
              const accentLightInHex = hslToHex(colors.accentLight.h, colors.accentLight.s, colors.accentLight.l);
              const accentDarkInHex = hslToHex(colors.accentDark.h, colors.accentDark.s, colors.accentDark.l);
              const iconsInHex = hslToHex(colors.icon.h, colors.icon.s, colors.icon.l);

              // now convert the HEX to RGB AND RGB TO HSB
              const iconsinRGB = hexToRgb(iconsInHex);
              if (iconsinRGB === null) return;
              const gradientColor = adjustHsbValue(rgbToHsb(iconsinRGB));
              // HSB TO HEX
              const gradientColorInHex = hsbToHex(gradientColor.h, gradientColor.s, gradientColor.b);
              // Hex to RGB
              const gradientColorInRGB = hexToRgb(gradientColorInHex);
              const shadowColorInRGB = hexToRgb(project.settings?.project_color);
              // Set the project colors
              setProjectColors?.({
                accent: project.settings?.project_color || '',
                accentLight: accentLightInHex,
                accentDark: accentDarkInHex,
                icons: iconsInHex,
                gradientInHex: gradientColorInHex,
                gradientInRgba: `rgba(${gradientColorInRGB?.r}, ${gradientColorInRGB?.g}, ${gradientColorInRGB?.b}, 0.00)`,
                shadowInRgba: `rgba(${shadowColorInRGB?.r}, ${shadowColorInRGB?.g}, ${shadowColorInRGB?.b}, 0.25)`,
              });
            } else {
              console.error('Invalid HSL format');
            }
          }
        } catch (error) {
          handleError(error);
        }
      };

      setColors();
    }
  }, [project, setProjectColors]);

  if (loading && !isMobile)
    return (
      <>
        <ShowcaseFixedNavigationBar />
        <Stack py={10} px={15} sx={{}}>
          <Grid container alignItems={'center'}>
            <Grid item xs={6} sm={12} md={6} lg={6} sx={{ background: 'white' }}>
              <Skeleton
                variant="rectangular"
                sx={{ width: '169px', height: '16px', borderRadius: '80px', mb: 2 }}
              ></Skeleton>
              <Skeleton
                variant="rectangular"
                sx={{ width: '430px', height: '24px', borderRadius: '80px', mb: 2 }}
              ></Skeleton>
              <Skeleton
                variant="rectangular"
                sx={{ width: '363px', height: '24px', borderRadius: '80px', mb: 2 }}
              ></Skeleton>
              <Skeleton variant="rectangular" sx={{ width: '431px', height: '24px', borderRadius: '80px' }}></Skeleton>
            </Grid>
            <Grid item xs={6} sm={12} md={6} lg={6} sx={{ background: 'white', justifyContent: 'right' }}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: '485px',
                  height: '16px',
                  borderRadius: '80px',
                  mb: 2,
                  mt: 2,
                }}
              ></Skeleton>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: '363px',
                  height: '24px',
                  borderRadius: '80px',
                  mb: 2,
                }}
              ></Skeleton>
              <Skeleton variant="rectangular" sx={{ width: '200px', height: '24px', borderRadius: '8px' }}></Skeleton>
            </Grid>
          </Grid>
          <Skeleton
            sx={{
              border: '1px solid #E3E3E3',
              borderRadius: '12px',
              px: 3,
              width: '100%',
              height: '600px',
            }}
          ></Skeleton>

          <Grid container sx={{ alignContent: 'center', width: '100%', margin: 'auto' }}>
            {Array.from({ length: 3 }).map((_, index) => (
              <div style={{ flexDirection: 'row', display: 'flex', flex: 1 }}>
                <Skeleton variant="circular" width={'60px'} height={'60px'} sx={{ marginRight: 2 }}></Skeleton>
                <div>
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: '169px', height: '12px', borderRadius: '80px', mb: 1 }}
                  ></Skeleton>
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: '177px', height: '12px', borderRadius: '80px', mb: 1 }}
                  ></Skeleton>
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: '170px', height: '12px', borderRadius: '80px', mb: 1 }}
                  ></Skeleton>
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: '50px', height: '12px', borderRadius: '80px' }}
                  ></Skeleton>
                </div>
              </div>
            ))}
          </Grid>
          <Skeleton
            variant="rectangular"
            sx={{ width: '294px', height: '16px', borderRadius: '8px', mb: 1, mt: 17 }}
          ></Skeleton>
          <Skeleton
            variant="rectangular"
            sx={{ width: theme.breakpoints.down('sm') ? '500px' : '831px', height: '48px', borderRadius: '8px', mb: 1 }}
          ></Skeleton>
          <Skeleton
            variant="rectangular"
            sx={{ width: theme.breakpoints.down('sm') ? '600px' : '929px', height: '16px', borderRadius: '8px', mb: 1 }}
          ></Skeleton>
          <Skeleton
            variant="rectangular"
            sx={{ width: theme.breakpoints.down('sm') ? '400px' : '996px', height: '16px', borderRadius: '80px' }}
          ></Skeleton>
          <Skeleton
            sx={{
              border: '1px solid #E3E3E3',
              borderRadius: '12px',
              px: 3,
              width: '100%',
              height: '750px',
            }}
          ></Skeleton>
        </Stack>
      </>
    );

  // Not Found Page
  if (errorStatusCode === 404) {
    return <NotFoundPage />;
  }

  // Forbidden Page
  if (errorStatusCode === 403) {
    return <UnauthorizedPage />;
  }

  type ShowcaseHeaderSectionProps = {
    projectHeader: ShowcaseHeader | null;
  };

  const ShowcaseHeaderSectionComponent: React.FC<ShowcaseHeaderSectionProps> = ({ projectHeader }) => {
    if (projectHeader && projectHeader.showComponent === true) {
      return <ShowcaseHeaderSection projectHeader={projectHeader} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseTextImageAnimationSection.tsx
  type ShowcaseTextImageAnimationSectionProps = {
    projectAnimation: ShowcaseAnimation | null;
  };

  const ShowcaseTextImageAnimationSectionComponent: React.FC<ShowcaseTextImageAnimationSectionProps> = ({
    projectAnimation,
  }) => {
    if (projectAnimation !== null && projectAnimation.showComponent === true) {
      return <ShowcaseTextImageAnimationSection projectAnimation={projectAnimation} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseFeatureOverviewSection.tsx
  type ShowcaseFeatureOverviewSectionProps = {
    projectFeatureOverview: ShowcaseFeatureOverview | null;
  };

  const ShowcaseFeatureOverviewSectionComponent: React.FC<ShowcaseFeatureOverviewSectionProps> = ({
    projectFeatureOverview,
  }) => {
    if (projectFeatureOverview !== null && projectFeatureOverview.showComponent === true) {
      return <ShowcaseFeatureOverviewSection projectFeatureOverview={projectFeatureOverview} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseTextImageBlockSection.tsx
  type ShowcaseTextImageBlockSectionProps = {
    projectTextImage: ShowcaseTextImage | null;
  };

  const ShowcaseTextImageBlockSectionComponent: React.FC<ShowcaseTextImageBlockSectionProps> = ({
    projectTextImage,
  }) => {
    if (projectTextImage !== null && projectTextImage.showComponent === true) {
      return <ShowcaseTextImageBlockSection projectTextImage={projectTextImage} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseQuoteSection.tsx
  type ShowcaseQuoteSectionProps = {
    projectQuote: ShowcaseQuote | null;
  };

  const ShowcaseQuoteSectionComponent: React.FC<ShowcaseQuoteSectionProps> = ({ projectQuote }) => {
    if (projectQuote !== null && projectQuote.showComponent === true) {
      return <ShowcaseQuoteSection projectQuote={projectQuote} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseFeatureQuoteSection.tsx
  type ShowcaseFeatureQuoteSectionProps = {
    projectFeatureQuote: ShowcaseFeatureQuote | null;
  };

  const ShowcaseFeatureQuoteSectionComponent: React.FC<ShowcaseFeatureQuoteSectionProps> = ({
    projectFeatureQuote,
  }) => {
    if (projectFeatureQuote !== null && projectFeatureQuote.showComponent === true) {
      return <ShowcaseFeatureQuoteSection projectFeatureQuote={projectFeatureQuote} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseVideoOrImageSection.tsx
  type ShowcaseVideoOrImageSectionProps = {
    projectVideoOrImageWithFeatures: ShowcaseVideoOrImageWithFeatures | null;
  };

  const ShowcaseVideoOrImageSectionComponent: React.FC<ShowcaseVideoOrImageSectionProps> = ({
    projectVideoOrImageWithFeatures,
  }) => {
    if (projectVideoOrImageWithFeatures !== null && projectVideoOrImageWithFeatures.showComponent === true) {
      return <ShowcaseVideoOrImageSection projectVideoOrImageWithFeatures={projectVideoOrImageWithFeatures} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseAccordionImageVideoSection.tsx
  type ShowcaseAccordionImageVideoSectionProps = {
    projectAccordion: ShowcaseAccordion | null;
  };

  const ShowcaseAccordionImageVideoSectionComponent: React.FC<ShowcaseAccordionImageVideoSectionProps> = ({
    projectAccordion,
  }) => {
    if (projectAccordion !== null && projectAccordion.showComponent === true) {
      return <ShowcaseAccordionImageVideoSection projectAccordion={projectAccordion} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseTextBlockSection.tsx
  type ShowcaseTextBlockSectionProps = {
    projectTextBlock: ShowcaseTextBlock | null;
  };

  const ShowcaseTextBlockSectionComponent: React.FC<ShowcaseTextBlockSectionProps> = ({ projectTextBlock }) => {
    if (projectTextBlock !== null && projectTextBlock.showComponent === true) {
      return <ShowcaseTextBlockSection projectTextBlock={projectTextBlock} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseFeatureDescriptionSection.tsx
  type ShowcaseFeatureDescriptionSectionProps = {
    projectFeatureDescription: ShowcaseInDepthDescription | null;
  };

  const ShowcaseFeatureDescriptionSectionComponent: React.FC<ShowcaseFeatureDescriptionSectionProps> = ({
    projectFeatureDescription,
  }) => {
    if (projectFeatureDescription !== null && projectFeatureDescription.showComponent === true) {
      return <ShowcaseFeatureDescriptionSection projectFeatureDescription={projectFeatureDescription} />;
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  // ShowcaseDemoAndContact.tsx
  type ShowcaseDemoAndContactSectionProps = {
    projectDemoAndContact: ShowcaseContact | null;
    demoLink?: string | null;
  };

  const ShowcaseDemoAndContactSectionComponent: React.FC<ShowcaseDemoAndContactSectionProps> = ({
    projectDemoAndContact,
    demoLink,
  }) => {
    if (projectDemoAndContact?.data !== null && projectDemoAndContact?.data.attributes.showComponent === true) {
      return (
        <ShowcaseDemoAndContactSection
          projectDemoAndContact={projectDemoAndContact.data.attributes}
          demoLink={demoLink}
        />
      );
    } else {
      return null; // or return an alternative JSX if needed
    }
  };

  interface ScrollColorContainerProps {
    children: ReactNode;
    projectAccentColor: string | undefined;
    projectAccentLightColor: string | undefined;
  }

  const ScrollColorContainer: React.FC<ScrollColorContainerProps> = ({
    children,
    projectAccentColor,
    projectAccentLightColor,
  }) => {
    const [scrollPercentage, setScrollPercentage] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
        const percentage = (scrollTop / totalHeight) * 100;
        setScrollPercentage(percentage);
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const accentWidth = Math.min(scrollPercentage, 100); // Limit to 100%
    const accentLightWidth = 100 - accentWidth;
    return (
      <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 100 }}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: `${accentWidth}%`,
              height: '6px',
              background: projectAccentColor,
            }}
          ></div>
          <div
            style={{
              width: `${accentLightWidth}%`,
              height: '6px',
              background: projectAccentLightColor,
            }}
          ></div>
        </div>
        <div>{children}</div>
      </div>
    );
  };

  return (
    <MainLayout>
      <ScrollColorContainer
        projectAccentColor={projectColors?.accent}
        projectAccentLightColor={projectColors?.accentLight}
      >
        <ShowcaseFixedNavigationBar
          demoLink={project?.settings?.demo_url}
          setShareDialogBoxOpen={value => {
            setShowcaseShareDialogBoxOpen(value);
          }}
        />
      </ScrollColorContainer>

      {project !== null && project && (
        <>
          <ShowcaseShareDialogBox
            isOpen={showcaseShareDialogBoxOpen}
            onClose={() => setShowcaseShareDialogBoxOpen(false)}
            resources={[`${project?.project_id}`]}
          />
          <ShowcaseHeaderSectionComponent projectHeader={project.header} />
          {project.dynamicField.map((dynamicField, index) => {
            switch (dynamicField.__component) {
              case 'image-text-elements.animation':
                return (
                  <ShowcaseTextImageAnimationSectionComponent
                    key={index}
                    projectAnimation={dynamicField as ShowcaseAnimation}
                  />
                );
              case 'list-elements.feature-outline':
                return (
                  <ShowcaseFeatureOverviewSectionComponent
                    key={index}
                    projectFeatureOverview={dynamicField as ShowcaseFeatureOverview}
                  />
                );
              case 'image-text-elements.text-image':
                return (
                  <ShowcaseTextImageBlockSectionComponent
                    key={index}
                    projectTextImage={dynamicField as ShowcaseTextImage}
                  />
                );
              case 'text-elements.quote':
                return <ShowcaseQuoteSectionComponent key={index} projectQuote={dynamicField as ShowcaseQuote} />;
              case 'text-elements.feature-quote':
                return (
                  <ShowcaseFeatureQuoteSectionComponent
                    key={index}
                    projectFeatureQuote={dynamicField as ShowcaseFeatureQuote}
                  />
                );
              case 'image-text-elements.video-image':
                return (
                  <ShowcaseVideoOrImageSectionComponent
                    key={index}
                    projectVideoOrImageWithFeatures={dynamicField as ShowcaseVideoOrImageWithFeatures}
                  />
                );
              case 'list-elements.accordion':
                return (
                  <ShowcaseAccordionImageVideoSectionComponent
                    key={index}
                    projectAccordion={dynamicField as ShowcaseAccordion}
                  />
                );
              case 'text-elements.text-block':
                return (
                  <ShowcaseTextBlockSectionComponent key={index} projectTextBlock={dynamicField as ShowcaseTextBlock} />
                );
              case 'image-text-elements.detail-feature':
                return (
                  <ShowcaseFeatureDescriptionSectionComponent
                    key={index}
                    projectFeatureDescription={dynamicField as ShowcaseInDepthDescription}
                  />
                );

              default:
                return null;
            }
          })}
          <ShowcaseDemoAndContactSectionComponent
            projectDemoAndContact={project.contact}
            demoLink={project.settings?.demo_url}
          />
          {nextProject !== undefined && <ShowcaseFooterNextProjectSection project={nextProject} />}
        </>
      )}

      <ShowcaseFooterGlobalSection />
    </MainLayout>
  );
};

export default ShowcaseProjectOverviewPage;

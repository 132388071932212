import { Box, Button, ButtonProps } from '@mui/material';
import { CopySmall } from 'pages/ShowcasePage/components/TypographyFonts';
import { FC } from 'react';
import { FixedColors } from 'theme';

import ShareArrowSVG from './share_arrow.svg';

interface ShowcaseHeaderButtonProps extends ButtonProps {
  projects?: number;
}

const ShowcaseHeaderShareButton: FC<ShowcaseHeaderButtonProps> = ({ projects = 0, onClick }) => {
  return (
    <Button
      sx={{
        backgroundColor: FixedColors.almostBlack,
        borderRadius: '8px',
        boxShadow: `0 0 0 1px ${FixedColors.almostBlack}`,
        maxHeight: '36px',
        '&:hover': {
          backgroundColor: FixedColors.almostBlack,
          boxShadow: `0 0 0 2px ${FixedColors.almostBlack}`,
        },
        '&:disabled': {
          backgroundColor: '#3F3F3F',
          boxShadow: `0 0 0 1px ${FixedColors.almostBlack}`,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '40px',
        textAlign: 'center',
        px: '13px',
      }}
      disabled={projects === 0}
      onClick={onClick}
    >
      <Box
        sx={{
          width: '15px',
          height: '15px',
          backgroundImage: `url(${ShareArrowSVG})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          mr: 1,
        }}
      />
      <CopySmall sx={{ color: FixedColors.white, textTransform: 'none', fontWeight: 400 }}>
        Share (<strong>{projects}</strong> {` project${projects > 1 || projects === 0 ? 's' : ''}`})
      </CopySmall>
    </Button>
  );
};

export default ShowcaseHeaderShareButton;

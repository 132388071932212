// MUI
import { Box, Card, Grid, Link, Stack } from '@mui/material';
import { ShowcaseProject } from 'ifaces';
import { FC, useContext, useState } from 'react';

import SampleProjectPNG from './ProjektImage.png';
import { Copy, Header1, Header2, Kicker } from 'pages/ShowcasePage/components/TypographyFonts';
import { CopyBold } from 'pages/ShowcasePage/components/TypographyFonts';
import { UIContext } from 'UIProvider';

interface ShowcaseGridViewItemProps {
  project: ShowcaseProject;
  imageSource?: string;
  isFirstProject: boolean;
  isLastProject: boolean;
}

const ShowcaseListViewItem: FC<ShowcaseGridViewItemProps> = ({
  project,
  imageSource,
  isFirstProject,
  isLastProject,
}) => {
  const { isMobile } = useContext(UIContext);
  const [isImageHovered, setImageHovered] = useState(false);
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ background: 'white', overflow: 'hidden', px: 5 }}>
      <Link
        sx={{
          textDecoration: 'none',
        }}
        href={'/projects/' + project.project_id}
      >
        <Stack
          sx={{
            border: isMobile ? '1px solid #E3E3E3' : 'unset',
            height: '650px',
            display: 'flex',
            borderRadius: isMobile ? '12px' : 'unset',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            sx={{
              paddingX: isMobile ? 1 : 'unset',
              mb: isImageHovered ? 3 : 10,
              transition: 'margin-bottom 0.5s ease-in-out',
              maxWidth: '1200px',
              margin: 'auto',
              mt: 15,
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} maxWidth={'lg'} sx={{}}>
              <Kicker sx={{ color: '#080808', opacity: 0.6, letterSpacing: '1.12px' }}>
                {project.settings?.project_main_category}
              </Kicker>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} flexDirection={'row'} width={'100%'}>
              <Grid container sx={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={12} sm={12} md={4} lg={4} flexDirection={'row'} sx={{}}>
                  <Header1
                    sx={{
                      paddingBottom: 2,
                      cursor: 'pointer',
                      lineHeight: '54px',
                    }}
                  >
                    {project.name}
                  </Header1>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} flexDirection={'row'}>
                  <div>
                    <Copy> {project.header?.description} </Copy>
                    <CopyBold sx={{ mt: 3, textDecoration: isImageHovered ? 'underline' : 'none' }}>
                      Mehr erfahren
                    </CopyBold>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: 10,
              alignSelf: 'center',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${imageSource})`,
              maxWidth: isImageHovered ? '1400px' : '1200px',
              // Background Image should be at the middle bottom
              backgroundPosition: 'center top',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: '12px 12px 0px 0px',
              boxShadow: '0px 76px 150px 0px rgba(0, 0, 0, 0.25)',
              overflow: 'hidden',
              // Animation when hovering
              transition: '0.5s ease-in-out', // Updated transition properties
              '&:hover': {
                width: '105%', // Increased width
                // height: '110%', // Increased height
                cursor: 'pointer',
                backgroundPosition: 'center top',
                mt: 5, // Updated background position on hover
              },
            }}
            onMouseEnter={() => setImageHovered(true)} // Set state variable to true on mouse enter
            onMouseLeave={() => setImageHovered(false)} // Set state variable to false on mouse leave
          />
        </Stack>
      </Link>
    </Grid>
  );
};

export default ShowcaseListViewItem;

import { UIContext } from 'UIProvider';
import { CSSProperties, FC, useContext, useEffect, useRef, useState } from 'react';
import { hexToRgb } from 'utils';

interface ImageComponentProps {
  imageOrVideo?: string;
  ImageStyle?: CSSProperties;
  ContainerShadow?: boolean;
  backgroundSize?: CSSProperties;
  extension?: string;
  ContainerShadowColor?: string;
  enableFade?: boolean;
}

const ImageComponent: FC<ImageComponentProps> = ({
  imageOrVideo,
  ImageStyle,
  ContainerShadow,
  ContainerShadowColor,
  backgroundSize,
  extension,
  enableFade,
}) => {
  var isMP4 = false;
  if (extension === '.mp4') {
    isMP4 = true;
  }
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<string | undefined>(undefined);

  const { isMobile } = useContext(UIContext);
  useEffect(() => {
    const calculateContainerHeight = () => {
      if (containerRef.current) {
        const image = new Image();
        image.onload = () => {
          const { width, height } = image;
          const containerWidth = containerRef.current?.offsetWidth;
          if (containerWidth) {
            const calculatedHeight = (height * containerWidth) / width;
            setContainerHeight(`${calculatedHeight}px`);
          }
        };
        image.src = imageOrVideo || '';
      }
    };

    calculateContainerHeight(); // Calculate the container height initially

    window.addEventListener('resize', calculateContainerHeight); // Listen to resize event

    return () => {
      window.removeEventListener('resize', calculateContainerHeight); // Clean up the event listener
    };
  }, [imageOrVideo, isMobile]);

  const containerStyles: CSSProperties = {
    position: 'relative',
    borderRadius: '12px',
    width: '100%',
    height: containerHeight, // Set the container height dynamically
    overflow: 'hidden',
    filter:
      ContainerShadow || ContainerShadowColor
        ? `drop-shadow(0px 76px 150px ${ContainerShadowColor || 'rgba(0, 0, 0, 0.35)'})`
        : undefined,
  };

  const videoStyles: CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '12px',
    opacity: enableFade ? 0 : 1,
    transition: enableFade ? `opacity 0.45s ease-in-out` : 'unset',
  };

  const backgroundImageStyles: CSSProperties = {
    ...ImageStyle,
    backgroundImage: `url(${imageOrVideo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    backgroundSize: backgroundSize ? '' : 'contain',
    width: '100%',
    height: '100%',
    opacity: enableFade ? 0 : 1,
    transition: enableFade ? `opacity 0.45s ease-in-out` : 'unset',
  };

  return (
    <div style={containerStyles} ref={containerRef}>
      {isMP4 ? (
        <video src={imageOrVideo} autoPlay loop muted style={videoStyles} />
      ) : (
        <div style={backgroundImageStyles} />
      )}
    </div>
  );
};

export default ImageComponent;

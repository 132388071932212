import { useState, useEffect } from 'react';

// Define general type for useSize hook, which includes width and height
interface Size {
  width: number;
  height: number;
}

// Hook
function useSize(stickyElem: HTMLElement | null): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (!stickyElem) return;
      const elementHeight = stickyElem.getBoundingClientRect().height;
      const elementWidth = stickyElem.getBoundingClientRect().width;
      // Set window width/height to state
      setSize({
        width: elementWidth,
        height: elementHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [stickyElem]); // Empty array ensures that effect is only run on mount
  return size;
}

export default useSize;

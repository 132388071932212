// MUI
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Stack } from '@mui/material';
import useWindowSize from 'components/hooks/useWindowSize';
import { FC, useContext, useState } from 'react';
import { Copy, CopyBold, Header2, Kicker } from '../components/TypographyFonts';
import { FixedColors } from 'theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageComponent from '../components/ImageComponent';
import { ElyvateColors, UIContext } from 'UIProvider';
import { ShowcaseAccordion, ShowcaseApp } from 'ifaces';
import React from 'react';
import exampleImage from '../../../assets/example2.png';

interface RenderAccordianComponentProps {
  isMobile?: boolean;
  header?: string;
  description?: string;
  imageOrVideo?: string;
  index: number;
  expandedIndex: number | null;
  onExpand?: (index: number | null, value?: string) => void;
  mediaExtension?: string;
  backgroundColor?: string;
}

const RenderAccordianComponent: FC<RenderAccordianComponentProps> = ({
  isMobile,
  header,
  description,
  imageOrVideo,
  index,
  expandedIndex,
  onExpand,
  mediaExtension,
  backgroundColor,
}) => {
  const isExpanded = index === expandedIndex;

  const handleClick = () => {
    if (!isExpanded) {
      onExpand && onExpand(index, imageOrVideo); // Open the accordion
    }
  };

  return (
    <Stack>
      <Divider
        style={{
          borderBottom: 'white 1px solid',
          marginTop: '10px',
        }}
      />
      <Accordion sx={{ backgroundColor: backgroundColor }} expanded={isExpanded} onChange={handleClick}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: FixedColors.white }} />} sx={{ padding: 1 }}>
          <CopyBold sx={{ color: FixedColors.white }}>{header}</CopyBold>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 1 }}>
          <Copy sx={{ color: FixedColors.white, marginBottom: isMobile ? 2 : null }}>{description}</Copy>
          {isMobile && <ImageComponent imageOrVideo={imageOrVideo} extension={mediaExtension} />}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

interface ShowcaseAccordionImageVideoSectionProps {
  projectAccordion: ShowcaseAccordion;
}

const ShowcaseAccordionImageVideoSection: FC<ShowcaseAccordionImageVideoSectionProps> = ({ projectAccordion }) => {
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;

  const [expandedImage, setExpandedImage] = useState<string | undefined>(
    projectAccordion.featureWithImage[0].image.data.attributes.url,
  );
  const [expandedImageExtension, setExpandedImageExtension] = useState<string | undefined>(
    projectAccordion.featureWithImage[0].image.data.attributes.ext,
  );
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);
  const { projectColors } = useContext(UIContext);

  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleExpand = (
    index: React.SetStateAction<number | null>,
    value: React.SetStateAction<string | undefined>,
    expandedImageExtension: string | undefined,
  ) => {
    setIsFadingOut(true); // Start fading out
    setTimeout(() => {
      setExpandedIndex(index);
    }, 200);
    setTimeout(() => {
      setIsFadingOut(false); // Done fading out
      setExpandedImage(value);
      setExpandedImageExtension(expandedImageExtension);
    }, 500);
  };

  return (
    <Stack
      sx={{
        paddingTop: isMobile ? 8 : 15,
        paddingBottom: isMobile ? 8 : 15,
        backgroundColor: projectColors?.accentDark,
        paddingLeft: isMobile ? 4 : 15,
        paddingRight: isMobile ? 4 : 15,
      }}
    >
      <Grid
        container
        maxWidth={'lg'}
        marginTop={1}
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          margin: 'auto',
        }}
      >
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{ paddingRight: isMobile ? null : 6 }}>
          <Box sx={{ maxWidth: isMobile ? 'unset' : '384px' }}>
            <Kicker sx={{ color: FixedColors.white, textTransform: 'uppercase' }}>{projectAccordion.kickerText}</Kicker>
            <Header2 sx={{ color: FixedColors.white, marginTop: isMobile ? 1 : 1 }}>{projectAccordion.title}</Header2>
            <Copy sx={{ color: FixedColors.white, marginTop: isMobile ? 1 : 1, marginBottom: isMobile ? 1 : 1 }}>
              {projectAccordion.description}
            </Copy>

            {projectAccordion.featureWithImage.map((item, index) => {
              const imageUrl = item.image.data.attributes.url;
              return (
                <RenderAccordianComponent
                  key={index}
                  index={index}
                  expandedIndex={expandedIndex}
                  isMobile={isMobile}
                  header={item.name}
                  description={item.description}
                  imageOrVideo={imageUrl}
                  mediaExtension={item.image.data.attributes.ext}
                  onExpand={(index, value) => handleExpand(index, value, item.image.data.attributes.ext)}
                  backgroundColor={projectColors?.accentDark}
                />
              );
            })}
          </Box>
        </Grid>
        {!isMobile && expandedImage && (
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: isFadingOut ? '20px' : 'unset',
              transition: 'margin-top 0.45s cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          >
            <ImageComponent imageOrVideo={expandedImage} extension={expandedImageExtension} enableFade={isFadingOut} />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default ShowcaseAccordionImageVideoSection;

import { Button, ButtonProps } from '@mui/material';
import { CopySmall } from 'pages/ShowcasePage/components/TypographyFonts';
import { FC } from 'react';
import { FixedColors } from 'theme';

interface ShowcaseHeaderButtonProps extends ButtonProps {
  selected?: boolean;
  shouldBeInlineBlock?: boolean;
  isLastItem?: boolean;
}

const ShowcaseHeaderButton: FC<ShowcaseHeaderButtonProps> = ({
  children,
  selected,
  shouldBeInlineBlock,
  isLastItem = false,
  sx,
  onClick,
}) => {
  return (
    <Button
      sx={{
        backgroundColor: selected ? FixedColors.almostBlack : FixedColors.white,
        borderRadius: '8px',
        boxShadow: `0 0 0 1px ${FixedColors.almostBlack}`,
        maxWidth: '138px',
        maxHeight: '36px',
        '&:hover': {
          backgroundColor: !selected ? FixedColors.white : FixedColors.almostBlack,
          boxShadow: !selected ? `0 0 0 2px ${FixedColors.almostBlack}` : `0 0 0 1px ${FixedColors.almostBlack}`,
        },
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '40px',
        textAlign: 'center',
        px: 1,
        display: shouldBeInlineBlock ? 'inline-block' : 'flex',
        // Margin Right Only if it is not the last element
        marginRight: shouldBeInlineBlock && !isLastItem ? '8px' : 0,
        ...sx,
        cursor: !selected ? 'pointer' : 'unset',
      }}
      onClick={!selected ? onClick : undefined}
    >
      <CopySmall
        sx={{
          color: selected ? FixedColors.white : FixedColors.almostBlack,
          textTransform: 'none',
          fontWeight: 400,
          '&:hover': {
            color: selected ? 'white' : FixedColors.almostBlack,
          },
        }}
      >
        {children}
      </CopySmall>
    </Button>
  );
};

export default ShowcaseHeaderButton;

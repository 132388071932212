import React, { FC, useEffect, useState } from 'react';
import Vector from '../../../assets/Vector.png';

interface IconImageComponentProps {
  image: string;
  iconColor?: string;
}

const IconImageComponent: FC<IconImageComponentProps> = ({ image, iconColor }) => {
  const [svgContent, setSVGContent] = useState<string | null>(null);

  useEffect(() => {
    if (image) {
      fetch(image)
        .then(response => response.text())
        .then(svgContent => {
          // Check if the 'stroke' attribute is present, if so, do not replace the color
          const hasStrokeAttribute = /stroke="(.*?)"/.test(svgContent);

          if (!hasStrokeAttribute) {
            // Modify the SVG content to change the fill color to iconColor (if fill is present)
            const modifiedSvgContent = svgContent.replace(/fill="(.*?)"/g, `fill="${iconColor || '#000'}"`);
            setSVGContent(modifiedSvgContent);
          } else {
            const modifiedSvgContent = svgContent.replace(/stroke="(.*?)"/g, `stroke="${iconColor || '#000'}"`);
            setSVGContent(modifiedSvgContent);
          }
        })
        .catch(error => {
          console.error('Error fetching SVG:', error);
          setSVGContent(null);
        });
    }
  }, [image, iconColor]);

  return (
    <div
      style={{
        backgroundImage: svgContent ? `url(data:image/svg+xml,${encodeURIComponent(svgContent)})` : `url(${Vector})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '36px',
        height: '36px',
        position: 'relative',
        borderRadius: '10px',
        color: iconColor,
      }}
    />
  );
};

export default IconImageComponent;

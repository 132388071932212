// MUI
import { Stack } from '@mui/material';
import { Copy, Header1 } from '../components/TypographyFonts';
import useWindowSize from 'components/hooks/useWindowSize';
import { FC } from 'react';
import { ShowcaseApp, ShowcaseTextBlock } from 'ifaces';

interface ShowcaseTextBlockSectionProps {
  projectTextBlock: ShowcaseTextBlock;
}

const ShowcaseTextBlockSection: FC<ShowcaseTextBlockSectionProps> = ({ projectTextBlock }) => {
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;
  return (
    <Stack
      sx={{
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: isMobile ? 5 : 10,
        paddingBottom: isMobile ? 5 : 10,
        paddingLeft: isMobile ? 2 : 10,
        paddingRight: isMobile ? 2 : 10,
      }}
    >
      <Stack
        sx={{
          maxWidth: isMobile ? 'unset' : '792px',
        }}
      >
        <Header1 isMobile={isMobile} sx={{ marginBottom: '21px' }}>
          {projectTextBlock.title}
        </Header1>
        <Copy isMobile={isMobile} sx={{ marginTop: '10px' }}>
          {projectTextBlock.description}
        </Copy>
      </Stack>
    </Stack>
  );
};

export default ShowcaseTextBlockSection;

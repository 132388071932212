import { Box, Button, ButtonProps } from '@mui/material';
import { CopySmall } from 'pages/ShowcasePage/components/TypographyFonts';
import { FC } from 'react';
import { FixedColors } from 'theme';

import ShareArrowSVG from './list_icon.svg';
import GridIconSVG from './grid_icon.svg';

interface ShowcaseHeaderDisplayModeButtonProps extends ButtonProps {
  displayMode: 'grid' | 'list';
}

const ShowcaseHeaderDisplayModeButton: FC<ShowcaseHeaderDisplayModeButtonProps> = ({
  children,
  displayMode,
  onClick,
}) => {
  return (
    <Button
      sx={{
        backgroundColor: FixedColors.white,
        borderRadius: '8px',
        boxShadow: `0 0 0 1px ${FixedColors.almostBlack}`,
        maxWidth: '138px',
        maxHeight: '36px',
        '&:hover': {
          backgroundColor: FixedColors.white,
          boxShadow: `0 0 0 2px ${FixedColors.almostBlack}`,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '40px',
        textAlign: 'center',
        px: 1,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: '20px',
          height: '20px',
          backgroundImage: `url(${displayMode === 'grid' ? ShareArrowSVG : GridIconSVG})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          mr: 1,
        }}
      />
      <CopySmall sx={{ color: FixedColors.almostBlack, textTransform: 'none', fontWeight: 400 }}>{children}</CopySmall>
    </Button>
  );
};

export default ShowcaseHeaderDisplayModeButton;

// MUI
import ShowcaseMainHeaderSection from '../ShowcaseMainHeaderSection/ShowcaseMainHeaderSection';
import ShowcaseGridViewSection from '../ShowcaseGridViewSection/ShowcaseGridViewSection';
import ShowcaseFooterGlobalSection from '../ShowcaseFooterGlobalSection/ShowcaseFooterGlobalSection';
import ShowcaseWeAreSection from '../ShowcaseWeAreSection/ShowcaseWeAreSection';
import MainLayout from '../components/MainLayout';
import { useContext, useEffect, useState } from 'react';
import ShowcaseShareDialogBox from '../components/ShareDialog/ShowcaseShareDialogBox';
import ShowcaseListViewSection from '../ShowcaseListViewSection/ShowcaseListViewSection';
import { AuthCtx } from 'AuthProvider';
import { checkUserId, getAllUsers } from 'API';
import { auth } from '../../../Firebase';
import { BYDataCtx } from 'DataProvider';
import { ShowcaseUserPageViewType } from 'ifaces';
import { Stack } from '@mui/material';

const PageProjectOverviewAdmin = () => {
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);

  const [checkedAppsToShare, setCheckedAppsToShare] = useState<Array<string>>([]);

  const [gridOrListView, setGridOrListView] = useState<'grid' | 'list'>('grid');

  const { setShowcaseUserPageView, showcaseUserPageView } = useContext(BYDataCtx);
  const [projectVisibleForBeryllsUser, setProjectVisibleForBeryllsUser] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (auth.currentUser === null) return;
      const responseStatus = await checkUserId(auth.currentUser?.uid);
      if (responseStatus === 200) {
        setShowcaseUserPageView(ShowcaseUserPageViewType.ADMIN_OVERVIEW);
        setProjectVisibleForBeryllsUser(true);
      } else setShowcaseUserPageView(ShowcaseUserPageViewType.CLIENT_OVERVIEW);
    };
    fetchData(); // Call the async function inside the useEffect
  }, []);

  return (
    <MainLayout>
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           SHARE DIALOG                         //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <ShowcaseShareDialogBox
        isOpen={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        resources={checkedAppsToShare}
      />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                     SHOWCASE MODULAR SECTIONS                  //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}
      <ShowcaseMainHeaderSection
        projectsToShare={checkedAppsToShare.length}
        onShareButtonClick={() => {
          setShareDialogOpen(true);
        }}
        setGridOrListView={(value: 'grid' | 'list') => {
          setGridOrListView(value);
        }}
        headerOptionsVisible={projectVisibleForBeryllsUser}
      />
      {projectVisibleForBeryllsUser ? (
        <div>
          {gridOrListView === 'grid' ? (
            <ShowcaseGridViewSection
              storeCheckedApps={(value: string[]) => {
                setCheckedAppsToShare(value);
              }}
            />
          ) : (
            <ShowcaseListViewSection />
          )}
        </div>
      ) : (
        <ShowcaseListViewSection />
      )}

      <ShowcaseWeAreSection />

      <ShowcaseFooterGlobalSection showCaseUserPageViewType={showcaseUserPageView} />
    </MainLayout>
  );
};

export default PageProjectOverviewAdmin;

// MUI
import { Box, BoxProps, Grid, Stack, styled } from '@mui/material';
import useWindowSize from 'components/hooks/useWindowSize';
import { Copy, CopyBold, Kicker } from '../components/TypographyFonts';
import { Header1 } from '../components/TypographyFonts';
import Vector from '../../../assets/Vector.png';
import { FixedColors } from 'theme';
import { FC, useContext } from 'react';
import ImageComponent from '../components/ImageComponent';
import { ShowcaseVideoOrImageWithFeatures, showCaseFeature } from 'ifaces';
import { UIContext } from 'UIProvider';
import IconImageComponent from '../components/IconImageComponent';

const CenteredGridView = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '40px',
}));

interface SquaredBoxProps extends BoxProps {
  shadowColor?: string; // Add the custom prop shadowColor
}

const SquaredBox = styled(Box)<SquaredBoxProps>(({ theme, shadowColor }) => ({
  minWidth: '60px',
  height: '60px',
  borderRadius: '18.46px',
  backgroundColor: FixedColors.white,
  border: '1px solid #E3E3E3',
  marginRight: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  filter: `drop-shadow(0px 12px 20px ${shadowColor ?? 'rgba(0, 0, 0, 0.05)'})`, // Using shadowColor here
}));

interface RenderImageComponentProps {
  isMobile?: boolean;
  source: string;
  extension?: string;
}

const RenderImageComponent: FC<RenderImageComponentProps> = ({ isMobile, source, extension }) => {
  return <ImageComponent imageOrVideo={source} extension={extension} />;
};

interface RenderListComponentProps {
  isMobile?: boolean;
  feature: showCaseFeature;
}

const RenderListComponent: FC<RenderListComponentProps> = ({ isMobile, feature }) => {
  const { projectColors } = useContext(UIContext);
  return (
    <Grid
      container
      spacing={1}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: isMobile ? null : '384px',
      }}
    >
      <CenteredGridView item xs={12} sm={12} md={12} lg={12} xl={12}>
        <SquaredBox shadowColor={projectColors?.shadowInRgba}>
          <IconImageComponent
            image={feature.icon?.data ? feature.icon?.data.attributes.url : Vector}
            iconColor={projectColors?.icons}
          />
        </SquaredBox>
        <ContentStackView>
          <CopyBold isMobile={isMobile}>{feature.name}</CopyBold>
          <Copy isMobile={isMobile}>{feature.description}</Copy>
        </ContentStackView>
      </CenteredGridView>
    </Grid>
  );
};

const ContentStackView = styled(Stack)(({ theme }) => ({
  flex: 2,
}));

export interface ShowcaseVideoOrImageSectionProps {
  projectVideoOrImageWithFeatures: ShowcaseVideoOrImageWithFeatures;
}

const ShowcaseVideoOrImageSection: FC<ShowcaseVideoOrImageSectionProps> = ({ projectVideoOrImageWithFeatures }) => {
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;
  // imageUrl
  const imageUrl = projectVideoOrImageWithFeatures.media.image.data.attributes.url;
  const { projectColors } = useContext(UIContext);

  return (
    <Stack
      sx={{
        paddingX: isMobile ? 2 : 15,
        paddingTop: isMobile ? 4 : 10,
        paddingBottom: isMobile ? 4 : 10,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <Stack
        sx={{
          maxWidth: isMobile ? 'unset' : '1200px',
          zIndex: 2,
          width: '100%',
        }}
      >
        <Box
          sx={{
            paddingLeft: isMobile ? null : 0,
            paddingRight: isMobile ? null : 0,
          }}
        >
          <Kicker isMobile={isMobile} sx={{ textAlign: 'center' }}>
            {projectVideoOrImageWithFeatures.kickerText}
          </Kicker>
          <Header1 isMobile={isMobile} sx={{ textAlign: 'center' }}>
            {projectVideoOrImageWithFeatures.title}
          </Header1>
        </Box>

        <Stack sx={{ mt: isMobile ? 5 : 10 }}>
          <Grid
            container
            spacing={1}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
            flexDirection={'row'}
          >
            {isMobile ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ marginTop: isMobile ? 4 : null, marginBottom: isMobile ? 4 : null }}
                >
                  <ImageComponent
                    imageOrVideo={projectVideoOrImageWithFeatures.media.image.data.attributes.url}
                    extension={projectVideoOrImageWithFeatures.media.image.data.attributes.ext}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {projectVideoOrImageWithFeatures.feature.map((feature, index) => (
                    <RenderListComponent isMobile={isMobile} feature={feature} key={index} />
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  {projectVideoOrImageWithFeatures.feature.map((feature, index) => (
                    <RenderListComponent isMobile={isMobile} feature={feature} key={index} />
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  sx={{ marginTop: isMobile ? 4 : null, marginBottom: isMobile ? 4 : null }}
                >
                  <ImageComponent
                    imageOrVideo={projectVideoOrImageWithFeatures.media.image.data.attributes.url}
                    ContainerShadowColor={projectColors?.shadowInRgba}
                    extension={projectVideoOrImageWithFeatures.media.image.data.attributes.ext}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            backgroundImage: `linear-gradient(180deg, ${projectColors?.gradientInHex} 0%, ${projectColors?.gradientInRgba} 100%)`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ShowcaseVideoOrImageSection;

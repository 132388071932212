import axios from 'axios';
import { API_URL, REACT_APP_SHOWCASE_API_URL, REACT_APP_ADMIN_API_URL } from './config';
import { headerConfig } from './Firebase';
import { CaseCode, ShowcaseApp, ShowcaseProject, SystemFirebaseUser } from './ifaces';

import { AddNewSkillResponse, AddNewTechStackResponse } from 'pages/Skills/ifaces';

export const getCaseCodes = async (): Promise<CaseCode[]> => {
  const res = await axios.get(`${API_URL}/codes`, await headerConfig());
  return res.data as CaseCode[];
};

export const addNewSkill = async (data: unknown): Promise<AddNewSkillResponse> => {
  const res = await axios.post(`${API_URL}/skills`, data, await headerConfig()).catch(e => e.response);
  return res?.data as AddNewSkillResponse;
};

export const addNewTechStack = async (data: unknown): Promise<AddNewTechStackResponse> => {
  const res = await axios.post(`${API_URL}/tech-stacks`, data, await headerConfig()).catch(e => e.response);
  return res?.data as AddNewTechStackResponse;
};

// ADMIN ENDPOINTS

export const getAllUsers = async (): Promise<SystemFirebaseUser[] | null> => {
  try {
    const res = await axios.get(`${REACT_APP_ADMIN_API_URL}/users`, await headerConfig()).catch(e => e.response);
    return res?.data as SystemFirebaseUser[];
  } catch (e) {
    console.log(e);
    return null; // Return null or handle the error appropriately.
  }
};

export const getUser = async (email: string): Promise<{ uid: string } | null> => {
  try {
    const res = await axios.get(`${REACT_APP_SHOWCASE_API_URL}/user`, {
      ...(await headerConfig()),
      params: {
        email: email,
      },
    });

    return res.data as { uid: string };
  } catch (e) {
    console.log(e);
    return null; // Return null or handle the error appropriately.
  }
};

export const checkUserId = async (uid: string): Promise<number | null> => {
  try {
    const res = await axios.get(`${REACT_APP_SHOWCASE_API_URL}/user-id`, {
      ...(await headerConfig()),
      params: {
        uid: uid,
      },
    });

    return res.status;
  } catch (e) {
    console.log(e);
    return null; // Return null or handle the error appropriately.
  }
};

export const setUserPermissions = async (uid: string, permissions: string[]): Promise<void> => {
  await axios
    .post(
      `${REACT_APP_ADMIN_API_URL}/set-permissions`,
      { uid: uid, permissions: permissions.join(',') },
      await headerConfig(),
    )
    .catch(e => e.response);
};

export const setUserDivision = async (uid: string, division: string): Promise<void> => {
  await axios
    .post(`${REACT_APP_ADMIN_API_URL}/set-division`, { uid: uid, division: division }, await headerConfig())
    .catch(e => e.response);
};

// Showcase ENDPOINTS

// NEW ONES
export const getShowcaseProjects = async (): Promise<ShowcaseProject[]> => {
  const res = await axios.get(`${REACT_APP_SHOWCASE_API_URL}/projects`, await headerConfig());
  return res.data as ShowcaseProject[];
};

export const getShowcaseProject = async (project_id: string): Promise<ShowcaseProject> => {
  const res = await axios.get(`${REACT_APP_SHOWCASE_API_URL}/projects/${project_id}/`, await headerConfig());
  return res.data as ShowcaseProject;
};

type InviteTokenResponse = {
  invite_token: string;
};

export const postInvitedUser = async (invitee: string, resources: string): Promise<any> => {
  const res = await axios.post(
    `${REACT_APP_SHOWCASE_API_URL}/invited-users`,
    {
      invitee: invitee,
      resources: resources,
    },
    await headerConfig(),
  );
  return res.data as any;
};

export const requestInviteToken = async (invitee: string, resources: string[]): Promise<InviteTokenResponse> => {
  const res = await axios.post(
    `${REACT_APP_SHOWCASE_API_URL}/invite`,
    {
      invitee: invitee,
      resources: resources,
    },
    await headerConfig(),
  );
  return res.data as InviteTokenResponse;
};

type RedeemTokenResponse = {
  token: string;
};

export const redeemInviteToken = async (token: string): Promise<RedeemTokenResponse> => {
  const res = await axios.post(
    `${REACT_APP_SHOWCASE_API_URL}/token`,
    {
      token: token,
    },
    await headerConfig(),
  );
  return res.data as RedeemTokenResponse;
};

// MUI
import { Box, Card, Grid, Link, Stack } from '@mui/material';
import { ShowcaseApp, ShowcaseProject } from 'ifaces';
import { FC, useState } from 'react';

import SampleProjectPNG from './ProjektImage.png';
import { Header2, Kicker } from 'pages/ShowcasePage/components/TypographyFonts';
import CheckIcon from '@mui/icons-material/Check';

interface ShowcaseGridViewItemProps {
  project: ShowcaseProject;
  handleChecked: (app: ShowcaseProject) => void;
  imageSource?: string;
}

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange }) => {
  return (
    <label
      style={{
        position: 'relative',
        width: '24px',
        height: '24px',
        borderRadius: '4px',
        border: '1.5px solid #080808',
        backgroundColor: checked ? 'black' : 'transparent',
      }}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        style={{
          appearance: 'none',
          opacity: 0,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          backgroundColor: 'red',
        }}
      />
      <CheckIcon
        sx={{
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '18px',
          height: '18px',
          borderRadius: '1px',
          backgroundColor: checked ? 'black' : 'transparent',
          cursor: 'pointer',
          color: checked ? 'white' : 'transparent',
          '&:hover': {
            backgroundColor: 'white',
            color: 'rgba(0, 0, 0, 0.6)',
          },
        }}
      />
    </label>
  );
};

const ShowcaseGridViewItem: FC<ShowcaseGridViewItemProps> = ({ project, handleChecked, imageSource }) => {
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!checked);
    handleChecked(project);
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Link
        sx={{
          textDecoration: 'none',
        }}
        href={'/projects/' + project.project_id}
      >
        <Card
          sx={{
            border: '1px solid #E3E3E3',
            height: '266px',
            boxShadow: 'none',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack sx={{ alignItems: 'flex-end', display: 'flex', mt: '18px', mr: '18px' }}>
            <CustomCheckbox checked={checked} onChange={handleToggle} />
          </Stack>

          <Kicker sx={{ color: '#080808', opacity: 0.5, px: 5 }}>{project.settings?.project_main_category}</Kicker>

          <Header2
            sx={{
              px: 5,
              cursor: 'pointer',
            }}
          >
            {project.name}
          </Header2>
          <Box
            sx={{
              mt: 2,
              alignSelf: 'center',
              width: '80%',
              height: '100%',
              backgroundImage: `url(${imageSource})`,
              // Background Image should be at the middle bottom
              backgroundPosition: 'center top',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: '8px 8px 0px 0px',
              boxShadow: '0px 76px 150px 0px rgba(0, 0, 0, 0.25)',
              // Animation when hovering
              transition: '0.5s ease-in-out',
              '&:hover': {
                width: '88%', // Increased width
                // height: '110%', // Increased height
                cursor: 'pointer',
                mt: 1.7,
              },
            }}
          />
        </Card>
      </Link>
    </Grid>
  );
};

export default ShowcaseGridViewItem;

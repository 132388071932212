import useWindowSize from 'components/hooks/useWindowSize';
import React, { createContext, useState } from 'react';

// --------------------------------------------------------

// Context to hold Notifications Data.

export interface TISNotification {
  id?: string;
  type: 'success' | 'warning' | 'error' | 'info';
  message: string;
}

// --------------------------PROJECT SPECIFIC COLORS--------------------------//
interface variableColors {
  accent: string;
  accentLight: string;
  accentDark: string;
  icons: string;
  gradientInHex: string;
  gradientInRgba: string;
  shadowInRgba: string;
}

// Caroosy Colors of the Launchpad Project

export const CaroosyColors = {
  accent: '#FB8E68',
  accentLight: '#FEECE6',
  accentDark: '#411202',
  icons: '#FB8E68',
};

// MB Van Colors of the Launchpad Project

export const MBVanColors = {
  accent: '#0078D6',
  accentLight: '#E5F4FF',
  accentDark: '#002542',
  icons: '#0078D6',
};

// Elyvate Colors of the Launchpad Project

export const ElyvateColors = {
  accent: '#0000DB',
  accentLight: '#E5E5FF',
  accentDark: '#000042',
  icons: '#0000DB',
};

interface TISUIProps {
  notifications: Array<TISNotification>;
  addNotification: (notification: TISNotification) => void;
  removeNotification: (id: string) => void;
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
  projectColors?: variableColors;
  setProjectColors?: React.Dispatch<
    React.SetStateAction<{
      accent: string;
      accentLight: string;
      accentDark: string;
      icons: string;
      gradientInHex: string;
      gradientInRgba: string;
      shadowInRgba: string;
    }>
  >;
}

export const UIContext = createContext({} as TISUIProps);

export const UIProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<TISNotification>>([]);
  const [darkMode, setDarkMode] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : false;

  const [projectColors, setProjectColors] = useState({
    accent: '#0000DB',
    accentLight: '#E5E5FF',
    accentDark: '#000042',
    icons: '#0000DB',
    gradientInHex: '#EBEBFF',
    gradientInRgba: 'rgba(235, 235, 255, 0.00)',
    shadowInRgba: 'rgba(0, 0, 0, 0.25)',
  });

  //  handlers for notifications
  const removeNotification = (id: string) => {
    // remove notification with the given id
    setNotifications([...notifications].filter(notification => notification.id !== id));
  };

  const addNotification = (notification: TISNotification) => {
    setNotifications([...notifications, { ...notification }]);
  };

  return (
    <UIContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
        darkMode,
        setDarkMode,
        isMobile,
        projectColors,
        setProjectColors,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

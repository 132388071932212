import React, { useState, useContext } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { UIContext } from 'UIProvider';
import { CopySmallBold } from 'pages/ShowcasePage/components/TypographyFonts';
import { FC } from 'react';
import { FixedColors } from 'theme';
import BouncingDotsLoader from './BouncingDotsLoader';

interface ShareDialogButtonProps extends ButtonProps {
  loading?: boolean;
}

const ShareDialogButton: FC<ShareDialogButtonProps> = ({ children, onClick, loading }) => {
  const { isMobile } = useContext(UIContext);

  return (
    <Button
      sx={{
        backgroundColor: FixedColors.almostBlack,
        borderRadius: '8px',
        boxShadow: `0 0 0 1px ${FixedColors.almostBlack}`,
        height: isMobile ? '48px' : '58px',
        '&:hover': {
          backgroundColor: FixedColors.almostBlack,
          boxShadow: `0 0 0 2px ${FixedColors.almostBlack}`,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '40px',
        textAlign: 'center',
        px: '13px',
      }}
      onClick={onClick}
      disabled={loading} // Disable the button when loading is true
    >
      {/* Conditional rendering based on the loading state */}
      {loading ? (
        // Render the loader here (e.g., a spinning icon or bouncing dots)
        // You can use any loader component you prefer or customize this further
        // Example: <LoaderComponent />
        <BouncingDotsLoader />
      ) : (
        <CopySmallBold sx={{ color: FixedColors.white, textTransform: 'none', fontWeight: 600 }}>
          {children}
        </CopySmallBold>
      )}
    </Button>
  );
};

export default ShareDialogButton;

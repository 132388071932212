// MUI
import { Box, Dialog, Divider, Paper, Stack, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Copy, CopyBold, Header2 } from '../TypographyFonts';
import { postInvitedUser, requestInviteToken } from 'API';
import Step1InviteeName from './Step1InviteeName';
import Step2CopyInviteToken from './Step2CopyInviteToken';
import CopySuccessMessage from './CopySuccessMessage';
import { UIContext } from 'UIProvider';

interface ShowcaseShareDialogBoxProps {
  isOpen: boolean;
  onClose: () => void;
  onCodeGenerated?: () => void;
  resources?: string[];
}

enum ShareDialogSteps {
  STEP_1 = 'STEP_1', // Invitee Name Input
  STEP_2 = 'STEP_2', // Code Generation and Copy
}

export const ShowcaseShareDialogBox: FC<ShowcaseShareDialogBoxProps> = ({
  isOpen,
  onClose,
  resources = [],
  onCodeGenerated,
}) => {
  const { isMobile } = useContext(UIContext);

  const [inviteCode, setInviteCode] = useState('');

  const [error, setError] = useState<{ statusCode: number; message: string }>();
  const [displayPasswordCopiedSuccess, setDisplayPasswordCopiedSuccess] = useState(false);

  const [currentStep, setCurrentStep] = useState<ShareDialogSteps>(ShareDialogSteps.STEP_1);

  const handleRequestInviteCodeError = (error: unknown) => {
    // Check if the error has a status code of 409
    if (error instanceof Error && error.message.includes('409')) {
      // If it does, set the error state to an array with a single error object
      setError({ statusCode: 409, message: 'This user has already been invited.' });
      return { statusCode: 409, message: 'This user has already been invited.' };
    }
  };

  const handleRequestInviteCode = async (name: string) => {
    if (name.trim() !== '' && resources.length > 0) {
      try {
        // Store in Strapi
        await postInvitedUser(name, resources.join(', '));
        // Request Invite Token
        const tokenRequestResponse = await requestInviteToken(name, resources);
        // convert a resources array to a string seperated by comma

        setInviteCode(tokenRequestResponse.invite_token);
        setCurrentStep(ShareDialogSteps.STEP_2);
        if (onCodeGenerated) {
          onCodeGenerated();
        }
        return {
          statusCode: 200,
          message: 'success',
        };
      } catch (error) {
        const response = handleRequestInviteCodeError(error);
        return response;
      }
    }
  };

  const handleCloseDialog = () => {
    onClose();
    setError(undefined);
    setCurrentStep(ShareDialogSteps.STEP_1);
  };

  const handleInviteTokenCopied = () => {
    // Set isPasswordCopied to true
    setDisplayPasswordCopiedSuccess(true);

    // Start a timer to reset the isPasswordCopied state after 2 seconds
    setTimeout(() => {
      setDisplayPasswordCopiedSuccess(false);
    }, 3000);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              p: 5,
              maxWidth: '450px',
              position: 'relative',
              borderRadius: '8px',
              overflow: 'hidden',
              marginX: isMobile ? 5 : 'unset',
            }}
          >
            {children}
          </Paper>
        )}
      >
        <Box sx={{ height: '14px', width: '14px', position: 'absolute', top: '22px', right: '22px', zIndex: 1 }}>
          <CloseOutlinedIcon fontSize="small" onClick={handleCloseDialog} sx={{ cursor: 'pointer' }} />
        </Box>
        <Stack sx={{ maxWidth: '370px' }}>
          <Header2 sx={{ mb: 2 }}>Share Showcase</Header2>
          <Copy sx={{ mb: 2 }}>
            You’ll share{' '}
            <CopyBold sx={{ display: 'inline' }}>
              {resources.length} Project{resources.length !== 1 ? 's' : ''}
            </CopyBold>
            .
          </Copy>

          <Divider sx={{ border: '1px solid #080808', opacity: 0.2, mb: 2 }} />

          <Stack sx={{ maxWidth: '370px' }}>
            {currentStep === ShareDialogSteps.STEP_1 && (
              <Step1InviteeName onSubmitInviteeName={handleRequestInviteCode} errorMessage={error?.message} />
            )}

            {currentStep === ShareDialogSteps.STEP_2 && (
              <Step2CopyInviteToken onInviteTokenCopied={handleInviteTokenCopied} inviteCode={inviteCode} />
            )}
          </Stack>
          {displayPasswordCopiedSuccess && <CopySuccessMessage onHide={() => setDisplayPasswordCopiedSuccess(false)} />}
        </Stack>
      </Dialog>
    </>
  );
};

export default ShowcaseShareDialogBox;

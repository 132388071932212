import React, { ChangeEvent, FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// FIREBASE
import { getAuth, OAuthProvider, signInWithPopup, signInWithCustomToken } from 'firebase/auth';
import { ref, uploadBytes } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { storage, firestore } from '../../Firebase';

// CUSTOM
import { ReactComponent as LightMicrosoftSignIn } from '../../assets/svg/ms-signin.svg';
import BackgroundFullSVG from '../../assets/svg/Background_Blur_full.svg';
import MobileBackgroundLight2 from '../../assets/svg/MobileBackgroundLight2.svg';
import { ReactComponent as BeryllsLogo } from '../../assets/svg/Logo_Black_svg.svg';

// MUI
import { BYPeople } from '../BDV/API';
import { AuthCtx } from '../../AuthProvider';
import { Box, Button, ButtonBaseProps, InputBase, Paper, Stack } from '@mui/material';
import useWindowSize from 'components/hooks/useWindowSize';
import { Header2 } from 'pages/ShowcasePage/components/TypographyFonts';
import { Copy } from 'pages/ShowcasePage/components/TypographyFonts';
import { CopySmall } from 'pages/ShowcasePage/components/TypographyFonts';
import { CopyBold } from 'pages/ShowcasePage/components/TypographyFonts';
import { FixedColors } from 'theme';
import { redeemInviteToken } from 'API';

const SignInWithMicrosoftButton: FC<ButtonBaseProps> = ({ onClick }) => (
  <Button
    sx={{
      p: '8px',
      boxShadow: 'rgb(32 54 86 / 20%) 0px 2px 6px',
      borderRadius: '5px',
      overflow: 'hidden',
    }}
    onClick={onClick}
  >
    <LightMicrosoftSignIn />
  </Button>
);

interface WindowsLocationType {
  from: Location | undefined;
}

export default function SignInPage() {
  let location = useLocation();
  let navigate = useNavigate();

  let state = location.state as WindowsLocationType | undefined;
  let from = state?.from?.pathname || '/';

  // CONTEXTS
  const { user } = React.useContext(AuthCtx);
  const { width } = useWindowSize();

  // State
  const [password, setPassword] = React.useState<string>('');
  const [error, setError] = React.useState<string>();
  const [viewIsMobile, setViewIsMobile] = React.useState<boolean>(false);

  useEffect(() => {
    if (width && width < 600) {
      setViewIsMobile(true);
    } else {
      setViewIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (user) {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    }
  }, [from, navigate, user]);

  // https://gdpr.eu/cookies/
  // State variable to control whether to show the cookie consent modal.
  // const [showConsentRequest, setShowConsentRequest] = React.useState(false);
  // const [cookies, setCookie] = useCookies(['privacy_modal_displayed', 'privacy_consent_functional', 'preferences']);
  // const [loginErrorMsg, setLoginErrorMsg] = React.useState<string>('');

  const getMicrosoftProfilePicture = async (accessToken: string): Promise<any> => {
    const res = await axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'image/jpg',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  };

  const handleSignInWithMicrosoft = async () => {
    var provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: 'fc4b9672-10b3-4192-bf77-973d3433b822',
    });
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);

      const employeeRef = doc(firestore, 'employees', result.user.uid);
      const employeeDoc = await getDoc(employeeRef);

      // Get Microsoft Provider Token
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = (credential as any).accessToken;

      const getMicrosoftProfilePictureAndUpload = async (userPhotoPath: string) => {
        const data = await getMicrosoftProfilePicture(accessToken);
        var userPhoto = ref(storage, userPhotoPath);
        var metadata = {
          contentType: 'image/jpeg',
        };
        // Upload the file and metadata
        await uploadBytes(userPhoto, data, metadata);
      };

      const userPhotoPath = `user_images/${auth.currentUser?.uid}/${result.user.uid}.jpg`;
      if (employeeDoc.exists()) {
        const employeeData = employeeDoc.data() as BYPeople;
        if (!employeeData.photoURL) {
          await getMicrosoftProfilePictureAndUpload(userPhotoPath);
          await updateDoc(employeeRef, {
            photoURL: userPhotoPath,
          });
        }
      } else {
        await getMicrosoftProfilePictureAndUpload(userPhotoPath);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleSignInWithInvitePassword = async () => {
    if (!password) {
      setError('Please enter a password');
      return;
    }

    try {
      const customTokenResponse = await redeemInviteToken(password);

      const auth = getAuth();
      await signInWithCustomToken(auth, customTokenResponse.token);

      const currentUser = auth.currentUser;
      if (currentUser) {
        const idTokenResult = await currentUser.getIdTokenResult();
        const expirationTime = Number(idTokenResult.expirationTime); // Convert to number

        // Check if expiration time is reached
        const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
        if (expirationTime <= currentTime) {
          setError('Token expired. Please sign in again.');
          return;
        }
      }

      // Rest of the code after successful sign-in
    } catch (error) {
      // Handle the error
      // Set the error state or perform any other error handling
      setError('Please Enter a Valid Password');
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
    setError(undefined);
  };

  return (
    <Stack
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'white',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Stack
        sx={{
          position: 'fixed',
          right: '0',
          left: '0',
          top: '0',
          height: '64px',
          justifyContent: 'center',
          zIndex: 100,
          backgroundColor: 'white',
        }}
      >
        {viewIsMobile ? (
          <Stack
            sx={{
              width: '155px',
              height: viewIsMobile ? '30px' : '40px',
              zIndex: 2,
            }}
          >
            <BeryllsLogo />
          </Stack>
        ) : (
          <Stack
            sx={{
              position: 'absolute',
              left: '120px',
              width: '155px',
              top: '12px',
              height: '40px',
              zIndex: 2,
            }}
          >
            <BeryllsLogo />
          </Stack>
        )}
      </Stack>

      <Stack
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          backgroundImage: viewIsMobile ? `url(${MobileBackgroundLight2})` : `url(${BackgroundFullSVG})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <Paper
        sx={{
          width: viewIsMobile ? '90%' : '450px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          p: viewIsMobile ? 3 : 5,
          zIndex: 5,
          boxShadow: '0px 76px 150px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Stack>
          <Header2>Enter password</Header2>
          <Copy sx={{ mt: 2 }}>Please enter your password to access the Berylls Digital Ventures Showcase</Copy>
          <CopySmall sx={{ opacity: 0.6, mt: 2 }}>Password</CopySmall>
          <Box
            sx={{
              background: '#F5F5F5',
              px: 2,
              paddingBottom: 1,
              borderRadius: '8px',
              display: 'flex',
              mt: '0px',
            }}
          >
            <InputBase
              // Set Focus on input field
              autoFocus
              onChange={event => handleTextChange(event)}
              // Listen for enter key press
              value={password}
              type="password"
              sx={{
                flex: 1,
                color: 'black',
                borderRadius: '10px',
                fontSize: '1rem',
                py: '4px',
                height: '58px',
                fontFamily: 'Faktum',
                letterSpacing: '0.1em',
                '.Mui-disabled': {
                  '-webkit-text-fill-color': 'rgba(255, 255, 255, 0.54)',
                  color: 'rgba(255, 255, 255, 0.54)',
                },
                '.MuiInputBase-input:focus::placeholder': {
                  color: 'transparent',
                },
              }}
            />
          </Box>
          {error && <CopySmall sx={{ color: '#C23B3B' }}>{error}</CopySmall>}
          <Button
            onClick={() => handleSignInWithInvitePassword()}
            sx={{
              background: 'black',
              boxShadow: 'rgb(32 54 86 / 15%) 0px 2px 5px',
              borderRadius: '5px',
              overflow: 'hidden',
              p: '14px',
              mt: '16px',
              '&:hover': {
                background: 'black',
              },
            }}
          >
            <CopyBold sx={{ color: FixedColors.white, textTransform: 'none' }}>Login</CopyBold>
          </Button>
          <CopySmall sx={{ opacity: 0.6, mt: 2 }}>
            If you encounter any issues, simply talk to your Berylls contact person, and they will help you immediately.
          </CopySmall>
          <CopySmall sx={{ mt: 4, mb: 2 }}>For Berylls only use</CopySmall>
          <SignInWithMicrosoftButton onClick={() => handleSignInWithMicrosoft()} />
        </Stack>
      </Paper>
    </Stack>
  );
}

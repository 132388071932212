// MUI
import { Box, BoxProps, Grid, Stack, styled } from '@mui/material';
import { Copy, CopyBold, Header1, Header2, Header3, Kicker } from '../components/TypographyFonts';
import { FC, useContext } from 'react';
import { FixedColors } from 'theme';
import Vector from '../../../assets/Vector.png';
import ImageComponent from '../components/ImageComponent';
import { UIContext } from 'UIProvider';
import { ShowcaseApp, ShowcaseInDepthDescription } from 'ifaces';
import IconImageComponent from '../components/IconImageComponent';

interface SquaredBoxProps extends BoxProps {
  shadowColor?: string; // Add the custom prop shadowColor
}

const SquaredBox = styled(Box)<SquaredBoxProps>(({ theme, shadowColor }) => ({
  minWidth: '60px',
  height: '60px',
  borderRadius: '18px',
  backgroundColor: FixedColors.white,
  border: '0.8px solid #E3E3E3',
  marginRight: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  filter: `drop-shadow(0px 12px 20px ${shadowColor ?? 'rgba(0, 0, 0, 0.05)'})`,
}));

const CenteredGridView = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  marginBottom: '40px',
}));

const ContentStackView = styled(Stack)(({ theme }) => ({
  flex: 2,
}));

export type ShowcaseFeatureDescriptionSectionProps = {
  projectFeatureDescription: ShowcaseInDepthDescription;
};

const ShowcaseFeatureDescriptionSection: FC<ShowcaseFeatureDescriptionSectionProps> = ({
  projectFeatureDescription,
}) => {
  const { isMobile } = useContext(UIContext);
  // ImageUrl
  const imageUrl = projectFeatureDescription.media?.image.data.attributes.url;
  const imageExtension = projectFeatureDescription.media?.image.data.attributes.ext;
  const { projectColors } = useContext(UIContext);
  return (
    <Stack
      sx={{
        position: 'relative',
        alignItems: 'center',
        paddingTop: isMobile ? 4 : 10,
        paddingBottom: isMobile ? 4 : 10,
        paddingLeft: isMobile ? 2 : 15,
        paddingRight: isMobile ? 2 : 15,
      }}
    >
      <Stack
        sx={{
          maxWidth: isMobile ? 'unset' : '1200px',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            paddingLeft: isMobile ? null : 25,
            paddingRight: isMobile ? null : 25,
          }}
        >
          <Stack sx={{ maxWidth: isMobile ? 'unset' : '792px' }}>
            <Kicker isMobile={isMobile}>{projectFeatureDescription.kickerText}</Kicker>
            <Header1 isMobile={isMobile}>{projectFeatureDescription.mainHeading?.name}</Header1>
            <Copy isMobile={isMobile} sx={{ marginTop: '20px', marginBottom: '46px' }}>
              {projectFeatureDescription.mainHeading?.description}
            </Copy>
          </Stack>
        </Box>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <ImageComponent
            imageOrVideo={imageUrl}
            ContainerShadowColor={projectColors?.shadowInRgba}
            extension={imageExtension}
          />
          <Copy sx={{ fontSize: '16px', marginTop: isMobile ? 1 : 2, opacity: '0.6' }}>
            {projectFeatureDescription.legend}
          </Copy>
        </Box>
        <Box
          sx={{
            paddingLeft: isMobile ? null : 25,
            paddingRight: isMobile ? null : 25,
          }}
        >
          <Stack sx={{ maxWidth: isMobile ? 'unset' : '792px' }}>
            <Header2 isMobile={isMobile} sx={{ marginTop: '82px' }}>
              {projectFeatureDescription.subHeading?.name}
            </Header2>
            <Copy isMobile={isMobile} sx={{ marginTop: '16px' }}>
              {projectFeatureDescription.subHeading?.description}
            </Copy>
            <Header3 isMobile={isMobile} sx={{ marginTop: '48px' }}>
              {projectFeatureDescription.sectionHeading?.name}
            </Header3>
            <Copy isMobile={isMobile} sx={{ marginTop: '16px' }}>
              {projectFeatureDescription.sectionHeading?.description}
            </Copy>
            <Grid
              container
              sx={{
                marginTop: isMobile ? '40px' : '48px',
              }}
              spacing={2}
            >
              {projectFeatureDescription.feature &&
                projectFeatureDescription.feature.map((feature, index) => (
                  <CenteredGridView item xs={12} sm={12} md={6} lg={6} xl={6} padding={'0px'}>
                    <SquaredBox shadowColor={projectColors?.shadowInRgba}>
                      <IconImageComponent
                        image={feature.icon?.data ? feature.icon?.data.attributes.url : Vector}
                        iconColor={projectColors?.icons}
                      />
                    </SquaredBox>
                    <ContentStackView>
                      <CopyBold isMobile={isMobile}> {feature.name} </CopyBold>
                      <Copy isMobile={isMobile}>{feature.description}</Copy>
                    </ContentStackView>
                  </CenteredGridView>
                ))}
            </Grid>
          </Stack>
        </Box>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: isMobile ? '25%' : '30%',
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `linear-gradient(180deg, ${projectColors?.gradientInHex} 0%, ${projectColors?.gradientInRgba} 100%)`,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ShowcaseFeatureDescriptionSection;

// MUI
import { Box, BoxProps, Grid, Stack, styled } from '@mui/material';
import useWindowSize from 'components/hooks/useWindowSize';
import { Copy, CopyBold, Header1, Kicker } from '../components/TypographyFonts';
import { FC, useContext, useEffect, useState } from 'react';
import { FixedColors } from 'theme';
import Vector from '../../../assets/Vector.png';
import ImageComponent from '../components/ImageComponent';
import { ShowcaseApp, ShowcaseHeader, ShowcaseProject } from 'ifaces';
import { UIContext } from 'UIProvider';
import IconImageComponent from '../components/IconImageComponent';

interface SquaredBoxProps extends BoxProps {
  shadowColor?: string; // Add the custom prop shadowColor
}

const SquaredBox = styled(Box)<SquaredBoxProps>(({ theme, shadowColor }) => ({
  minWidth: '78px',
  height: '78px',
  borderRadius: '24px',
  backgroundColor: FixedColors.white,
  border: '1px solid #E3E3E3',
  marginRight: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  // filter: `drop-shadow(0px 12px 20px ${shadowColor ?? 'rgba(0, 0, 0, 0.05)'})`, // Using shadowColor here
}));

const CenteredGridView = styled(Grid)(({ theme }) => ({
  display: 'flex',
  // flex: 1,
  flexDirection: 'row',
  marginBottom: '40px',
}));

const ContentStackView = styled(Stack)(({ theme }) => ({
  flex: 2,
}));

interface ShowcaseHeaderSectionProps {
  projectHeader: ShowcaseHeader;
}

const ShowcaseHeaderSection: FC<ShowcaseHeaderSectionProps> = ({ projectHeader }) => {
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;
  const { projectColors } = useContext(UIContext);
  // ImageUrl
  const imageUrl = projectHeader.image.data.attributes.url;

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        paddingTop: isMobile ? 4 : 25,
        paddingBottom: isMobile ? 4 : 10,
        paddingLeft: isMobile ? 2 : 15,
        paddingRight: isMobile ? 2 : 15,
        mt: isMobile ? 6 : 'unset',
      }}
    >
      <Stack
        sx={{
          maxWidth: isMobile ? 'unset' : '1200px',
          zIndex: 2,
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: isMobile ? 3 : 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Kicker isMobile={isMobile} sx={{ textTransform: 'upperCase' }}>
              {projectHeader.kickerText}
            </Kicker>
            <Header1 isMobile={isMobile} sx={{ maxWidth: '588px' }}>
              {projectHeader.title}
            </Header1>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Stack sx={{ maxWidth: isMobile ? null : '486px' }}>
              <Copy isMobile={isMobile}>{projectHeader.description}</Copy>
            </Stack>
          </Grid>
        </Grid>
        <ImageComponent
          imageOrVideo={imageUrl}
          ContainerShadowColor={projectColors?.shadowInRgba}
          ContainerShadow={true}
          extension={projectHeader.image.data.attributes.ext}
        />
        <Grid
          container
          sx={{
            marginTop: isMobile ? 4 : 10,
          }}
          spacing={2}
        >
          {projectHeader.feature.map((feature, index) => (
            <CenteredGridView item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
              <SquaredBox shadowColor={projectColors?.shadowInRgba}>
                <IconImageComponent
                  image={feature.icon?.data ? feature?.icon?.data.attributes.url : Vector}
                  iconColor={projectColors?.icons}
                />
              </SquaredBox>
              <ContentStackView>
                <CopyBold isMobile={isMobile} sx={{ color: FixedColors.white }}>
                  {feature.name}
                </CopyBold>
                <Copy isMobile={isMobile} sx={{ color: FixedColors.white }}>
                  {feature.description}
                </Copy>
              </ContentStackView>
            </CenteredGridView>
          ))}
        </Grid>
      </Stack>

      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: projectColors?.accentDark,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ShowcaseHeaderSection;

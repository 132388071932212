// MUI
import { Box, Grid, Stack } from '@mui/material';
import { FC, useContext } from 'react';
import { UIContext } from 'UIProvider';
import { CopySmall, CopySmallBold } from '../components/TypographyFonts';
import { getAuth, signOut } from 'firebase/auth';

import BDVSVGLogo from './bdv_hoodie.svg';
import { ShowcaseUserPageViewType } from 'ifaces';

interface ShowcaseFooterGlobalSectionProps {
  showCaseUserPageViewType?: ShowcaseUserPageViewType;
}

const ShowcaseFooterGlobalSection: FC<ShowcaseFooterGlobalSectionProps> = ({ showCaseUserPageViewType }) => {
  const { isMobile } = useContext(UIContext);

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <Stack
        sx={{
          margin: isMobile ? undefined : 'auto',
          position: 'relative',
          alignItems: 'center',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 2,
          paddingRight: 2,
          background: 'white',
        }}
      >
        <Grid container spacing={2} sx={{ maxWidth: isMobile ? 'unset' : '1200px' }}>
          <Grid item xs={12} sm={12} md={4} lg={5} xl={5}>
            <Box>
              <Box
                sx={{
                  width: '139px',
                  height: '58px',
                  backgroundImage: `url(${BDVSVGLogo})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: isMobile ? null : 'right' }}>
                <CopySmall isMobile={isMobile}>Visited the berylls website</CopySmall>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ textAlign: isMobile ? null : 'right' }}>
                <CopySmall isMobile={isMobile}>Privacy Policy</CopySmall>
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1} sx={{ textAlign: isMobile ? null : 'right' }}>
                <CopySmall isMobile={isMobile}>Impressum</CopySmall>
              </Grid>
              {showCaseUserPageViewType === 'CLIENT_OVERVIEW' ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{
                    textAlign: isMobile ? null : 'right',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={handleSignOut}
                >
                  <CopySmallBold>Logout</CopySmallBold>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ShowcaseFooterGlobalSection;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';

// FIREBASE
// Importing the firebase module will initialize the firebase app client
import './Firebase';

// MUI
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// Context
import { AuthProvider } from './AuthProvider';
import { UIProvider } from './UIProvider';

// IMPORT PAGES
import SecureLayout from './SecureLayout';
import SignInPage from './pages/SignInPage/SignInPage';
import NotFoundPage from './pages/NotFoundPage';
import IndexPage from './pages/IndexPage';
import AdminPage from './pages/Admin';

// STYLES
import './themes/fonts.css';
import { BYDataProvider } from 'DataProvider';
import PageProjectOverviewAdmin from 'pages/ShowcasePage/PageProjectOverviewAdmin/PageProjectOverviewAdmin';
import ShowcaseProjectOverviewPage from 'pages/ShowcaseProjectOverviewPage/ShowcaseProjectOverviewPage';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <UIProvider>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<SignInPage />} />
              <Route
                path="/old"
                element={
                  <SecureLayout>
                    <IndexPage />
                  </SecureLayout>
                }
              />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                           BDV SHOWCASE                         //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route
                path="/"
                element={
                  <SecureLayout>
                    <BYDataProvider>
                      <PageProjectOverviewAdmin />
                    </BYDataProvider>
                  </SecureLayout>
                }
              />

              <Route path="/projects">
                <Route
                  path=":project_id"
                  element={
                    <SecureLayout>
                      <BYDataProvider>
                        <ShowcaseProjectOverviewPage />
                      </BYDataProvider>
                    </SecureLayout>
                  }
                />
              </Route>

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                         ADMIN PAGE ROUTE                       //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route
                path="/admin"
                element={
                  <SecureLayout title="Admin" adminRequired>
                    <AdminPage />
                  </SecureLayout>
                }
              />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                          NOT FOUND PAGE                        //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </UIProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

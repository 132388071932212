// MUI
import { Box, Card, Grid, Skeleton, Stack } from '@mui/material';
import { getShowcaseProjects } from 'API';
import useWindowSize from 'components/hooks/useWindowSize';
import { ShowcaseApp, ShowcaseProject } from 'ifaces';
import { FC, useContext, useEffect, useState } from 'react';
import { CopyBold, Header2, Kicker } from '../components/TypographyFonts';
import ShowcaseGridViewItem from './components/ShowcaseViewGridItem';
import { BYDataCtx } from 'DataProvider';
import React from 'react';

interface ShowcaseGridViewSectionProps {
  storeCheckedApps?: (app: Array<string>) => void;
}

const ShowcaseGridViewSection: FC<ShowcaseGridViewSectionProps> = ({ storeCheckedApps }) => {
  const [apps, setApps] = useState<Array<ShowcaseApp>>([]);
  const [checkedApps, setCheckedApps] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { projects, setProjects } = useContext(BYDataCtx);

  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projects = await getShowcaseProjects();
        setProjects(projects);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    fetchProjects();
  }, []);

  const handleCheckedApps = (app: ShowcaseProject) => {
    const updatedCheckedApps = checkedApps.includes(app.project_id)
      ? checkedApps.filter(checkedApp => checkedApp !== app.project_id)
      : [...checkedApps, app.project_id];

    setCheckedApps(updatedCheckedApps);

    if (storeCheckedApps) {
      storeCheckedApps(updatedCheckedApps);
    }
  };

  const SkeletonGridView = () => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Box
          sx={{
            height: '266px',
            width: '384px',
            borderRadius: '12px',
            border: '1px solid #E3E3E3',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            px: 5,
            flex: 1,
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              border: '1px solid #F5F5F5',
              borderRadius: '8px',
              mb: 1,
              mt: 5,
              zIndex: 1,
              width: '169px',
              height: '24px',
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{ border: '1px solid #F5F5F5', borderRadius: '8px', mb: 2, zIndex: 1, width: '276px', height: '36px' }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              border: '1px solid #E3E3E3',
              borderRadius: '8px 8px 0px 0px',
              zIndex: 1,
              width: '100%',
              height: '100%',
              backgroundColor: '#D0D0D0',
            }}
          />
        </Box>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        px: 2,
        py: isMobile ? 2 : 5,
      }}
    >
      <Stack
        sx={{
          maxWidth: isMobile ? 'unset' : '1200px',
          flex: 1,
          margin: 'auto',
        }}
      >
        {loading && !isMobile ? (
          <>
            <Skeleton
              variant="rectangular"
              sx={{ width: '456px', height: '24px', borderRadius: '8px', mb: 3 }}
            ></Skeleton>
            <Grid container alignItems={'center'} spacing={2}>
              {Array.from({ length: 3 }).map((_, index) => (
                <React.Fragment key={index}>{SkeletonGridView()}</React.Fragment>
              ))}
            </Grid>

            <Grid container alignItems={'center'} spacing={2} mt={5}>
              {Array.from({ length: 3 }).map((_, index) => (
                <React.Fragment key={index}>{SkeletonGridView()}</React.Fragment>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <CopyBold sx={{ marginBottom: 4 }}>Please select the projects you want to share.</CopyBold>
            <Grid container alignItems={'center'} spacing={2}>
              {projects.map((project, index) => {
                return (
                  <ShowcaseGridViewItem
                    key={`showcase-grid-view-item-${index}`}
                    project={project}
                    handleChecked={handleCheckedApps}
                    imageSource={project.header?.image.data.attributes.url}
                  />
                );
              })}
            </Grid>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ShowcaseGridViewSection;

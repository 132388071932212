import { styled } from '@mui/material/styles';
import React, { useContext } from 'react';
import Berylls_Y from '../assets/Logo_Black_Y.png';
import { UIContext } from '../UIProvider';

const BeryllsY = styled('div')({
  backgroundImage: `url(${Berylls_Y})`,
  backgroundSize: 'contain',
  width: '70px',
  height: '70px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});

export default function LoadingPage() {
  const { darkMode } = useContext(UIContext);
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: darkMode ? 'black' : 'white',
      }}
    >
      <BeryllsY />
    </div>
  );
}

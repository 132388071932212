// MUI
import { Box, Button, Grid, Stack, Link } from '@mui/material';
import { CopyBold, Header1 } from '../components/TypographyFonts';
import { Copy } from '../components/TypographyFonts';
import { FC, useContext } from 'react';
import { UIContext } from 'UIProvider';
import { FixedColors } from 'theme';
import UserContactImage from './UserContactImage';

export interface ShowcaseDemoAndContactSectionProps {
  projectDemoAndContact: {
    name: string;
    title: string;
    email: string;
    image?: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
  demoLink?: string | null;
}

const ShowcaseDemoAndContactSection: FC<ShowcaseDemoAndContactSectionProps> = ({ projectDemoAndContact, demoLink }) => {
  const { isMobile } = useContext(UIContext);

  const image = projectDemoAndContact?.image?.data?.attributes?.url;

  return (
    <Stack sx={{ py: 8, px: isMobile ? 2 : 6, alignItems: 'center' }}>
      <Grid maxWidth={'lg'} container spacing={2} rowSpacing={4}>
        <Grid item xs={12} md={6} p={6}>
          <Header1>Live-Demo</Header1>
          <Stack
            sx={{
              mt: 2,
            }}
            spacing={2}
          >
            <Copy>Teste unsere Applikation und überzeuge dich selbst von der Qualität.</Copy>
            <Link
              href={demoLink ? `${demoLink}` : undefined}
              target={demoLink ? '_blank' : undefined}
              rel={demoLink ? 'noopener noreferrer' : undefined}
              sx={{
                textDecoration: 'none',
                pointerEvents: demoLink ? 'auto' : 'none',
                cursor: demoLink ? 'pointer' : 'not-allowed',
              }}
            >
              <Button
                sx={{
                  backgroundColor: 'black',
                  borderRadius: '12px',
                  padding: '16px',
                  maxWidth: '217px',
                  maxHeight: '58px',
                  gap: '8px',
                  '&:hover': {
                    backgroundColor: 'black',
                  },
                }}
              >
                <CopyBold sx={{ color: FixedColors.white, textTransform: 'none' }}>View Demo</CopyBold>
              </Button>
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} p={6}>
          <Header1>Kontakt</Header1>
          <Stack
            direction={'row'}
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <UserContactImage imageStoragePath={image} userEmail={projectDemoAndContact.email} />
            <Stack
              sx={{
                justifyContent: 'center',
              }}
            >
              <CopyBold>{projectDemoAndContact.name}</CopyBold>
              <Copy>{projectDemoAndContact.title}</Copy>
              <Copy>{projectDemoAndContact.email}</Copy>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ShowcaseDemoAndContactSection;

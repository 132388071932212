// MUI
import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { getShowcaseProjects } from 'API';
import useWindowSize from 'components/hooks/useWindowSize';
import { ShowcaseProject } from 'ifaces';
import React, { FC, useEffect, useState } from 'react';
import ShowcaseListViewItem from './components/ShowcaseViewListItem';

interface ShowcaseGridViewSectionProps {}

const ShowcaseListViewSection: FC<ShowcaseGridViewSectionProps> = ({}) => {
  const [projects, setProjects] = useState<Array<ShowcaseProject>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projects = await getShowcaseProjects();
        setProjects(projects);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    fetchProjects();
  }, []);

  return (
    <Box
      sx={{
        // px: 5,
        border: 'none',
        background: 'white',
        width: '100%',
      }}
    >
      <Stack>
        {loading && !isMobile ? (
          <Stack alignItems={'center'} maxWidth={'lg'} sx={{ margin: 'auto', mt: 15 }}>
            {Array.from({ length: 2 }).map((_, index) => (
              <>
                <Grid container alignItems={'center'} spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} sx={{ background: 'white' }}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: '169px', height: '16px', borderRadius: '80px', mb: 2 }}
                    ></Skeleton>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: '485px', height: '24px', borderRadius: '80px', mb: 2 }}
                    ></Skeleton>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: '363px', height: '24px', borderRadius: '80px', mb: 2 }}
                    ></Skeleton>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: '431px', height: '24px', borderRadius: '80px' }}
                    ></Skeleton>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} sx={{ background: 'white', justifyContent: 'right' }}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: '485px', height: '16px', borderRadius: '80px', mb: 2 }}
                    ></Skeleton>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: '363px', height: '16px', borderRadius: '80px', mb: 2 }}
                    ></Skeleton>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: '200px', height: '16px', borderRadius: '8px' }}
                    ></Skeleton>
                  </Grid>
                </Grid>
                <Skeleton
                  sx={{
                    border: '1px solid #E3E3E3',
                    borderRadius: '12px 12px 0px 0px',
                    display: 'flex',
                    flexDirection: 'column',
                    px: 3,
                    width: '100%',
                    height: '550px',
                    backgroundColor: '#D0D0D0',
                  }}
                ></Skeleton>
              </>
            ))}
          </Stack>
        ) : (
          <Grid container alignItems={'center'} sx={{ maxWidth: isMobile ? 'unset' : '100%', margin: 'auto', flex: 1 }}>
            {projects.map((project, index) => {
              return (
                <ShowcaseListViewItem
                  key={`showcase-grid-view-item-${index}`}
                  project={project}
                  imageSource={project.header?.image.data.attributes.url}
                  isFirstProject={index === 0}
                  isLastProject={index === projects.length - 1}
                />
              );
            })}
          </Grid>
        )}
      </Stack>
    </Box>
  );
};

export default ShowcaseListViewSection;

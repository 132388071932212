import { ShowcaseProject, ShowcaseUserPageViewType } from 'ifaces';
import React, { createContext, useState } from 'react';

interface BYDataCtxProps {
  projects: ShowcaseProject[];
  setProjects: React.Dispatch<React.SetStateAction<ShowcaseProject[]>>;
  showcaseUserPageView: ShowcaseUserPageViewType | undefined;
  setShowcaseUserPageView: React.Dispatch<React.SetStateAction<ShowcaseUserPageViewType | undefined>>;
}

export const BYDataCtx = createContext({} as BYDataCtxProps);

export const BYDataProvider: React.FC = ({ children }) => {
  const [projects, setProjects] = useState<Array<ShowcaseProject>>([]);
  const [showcaseUserPageView, setShowcaseUserPageView] = useState<ShowcaseUserPageViewType | undefined>(undefined);

  return (
    <BYDataCtx.Provider
      value={{
        projects,
        setProjects,
        showcaseUserPageView,
        setShowcaseUserPageView,
      }}
    >
      {children}
    </BYDataCtx.Provider>
  );
};

// MUI
import { Stack } from '@mui/material';
import { Copy, CopySmall, Header1, Kicker, QuoteBold } from '../components/TypographyFonts';
import { FC, useContext } from 'react';
import { UIContext } from 'UIProvider';
import { ShowcaseApp, ShowcaseQuote } from 'ifaces';

interface ShowcaseQuoteSectionProps {
  projectQuote: ShowcaseQuote;
}

const ShowcaseQuoteSection: FC<ShowcaseQuoteSectionProps> = ({ projectQuote }) => {
  const { isMobile } = useContext(UIContext);
  return (
    <Stack
      sx={{
        py: 8,
        px: isMobile ? 2 : 15,
        margin: 'auto',
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{
          maxWidth: isMobile ? '311px' : '790px',
        }}
      >
        <Kicker isMobile={isMobile} sx={{ textAlign: 'center' }}>
          {projectQuote.title}
        </Kicker>
        <QuoteBold isMobile={isMobile} sx={{ textAlign: 'center', mt: 1 }}>
          {projectQuote.description}
        </QuoteBold>
        <CopySmall sx={{ textAlign: 'center', lineHeight: '20px', mt: 2 }}>
          <strong> {projectQuote.authorName}</strong>
          <br />
          {projectQuote.authorTitle}
        </CopySmall>
      </Stack>
    </Stack>
  );
};

export default ShowcaseQuoteSection;

import { Typography, styled } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import { UIContext } from 'UIProvider';
import { FC, useContext } from 'react';
import { FixedColors } from 'theme';

interface CustomTypographyProps extends TypographyProps {
  isMobile?: boolean;
}

export const BigTitle: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      component={'h1'}
      sx={{
        fontFamily: 'NeueFaktum-SemiBold',
        lineHeight: isMobile ? '46px' : '88px',
        color: FixedColors.almostBlack,
        fontSize: isMobile ? '42px' : '96px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Header1: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      component={'h1'}
      sx={{
        fontFamily: 'NeueFaktum-SemiBold',
        lineHeight: isMobile ? '46px' : '56px',
        color: FixedColors.almostBlack,
        fontSize: isMobile ? '40px' : '48px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Header2: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      component={'h2'}
      sx={{
        fontFamily: 'NeueFaktum-SemiBold',
        lineHeight: isMobile ? '38px' : '38px',
        color: FixedColors.almostBlack,
        fontSize: isMobile ? '32px' : '32px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Header3: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      component={'h3'}
      sx={{
        fontFamily: 'NeueFaktum-Regular',
        lineHeight: isMobile ? '38px' : '40px',
        color: FixedColors.almostBlack,
        fontSize: '32px',
        fontWeight: 400,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Copy: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      sx={{
        fontFamily: 'NeueFaktum-Regular',
        lineHeight: isMobile ? '26px' : '28px',
        color: FixedColors.almostBlack,
        fontSize: isMobile ? '18px' : '20px',
        fontWeight: 400,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const CopyBold: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: 'NeueFaktum-SemiBold',
        lineHeight: '26px',
        color: FixedColors.almostBlack,
        fontSize: isMobile ? '18px' : '20px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const CopySmall: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: 'NeueFaktum-Regular',
        lineHeight: '22px',
        color: FixedColors.almostBlack,
        fontSize: isMobile ? '14px' : '16px',
        fontWeight: 400,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const CopySmallBold: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: 'NeueFaktum-SemiBold',
        lineHeight: isMobile ? '22px' : '20px',
        color: FixedColors.almostBlack,
        fontSize: isMobile ? '14px' : '16px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Kicker: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  const { projectColors } = useContext(UIContext);
  return (
    <Typography
      component={'h3'}
      sx={{
        fontFamily: 'NeueFaktum-Bold',
        color: projectColors?.accent,
        fontWeight: 700,
        textTransform: 'uppercase',
        letterSpacing: '0.08em',
        lineHeight: isMobile ? '24px' : '28px',
        fontSize: isMobile ? '12px' : '14px',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const QuoteBold: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: 'NeueFaktum-SemiBold',
        lineHeight: isMobile ? '30px' : '46px',
        color: FixedColors.almostBlack,
        fontSize: isMobile ? '24px' : '36px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Quote: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: 'NeueFaktum-Regular',
        lineHeight: isMobile ? '30px' : '46px',
        color: FixedColors.almostBlack,
        fontSize: isMobile ? '24px' : '36px',
        fontWeight: 400,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

// MUI
import { Button, IconButton, Paper, Stack, styled, Dialog, TextField, Typography, Link } from '@mui/material';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

// Custom Components
import { ReactComponent as BeryllsLogo } from '../../../../assets/svg/Logo_Black_svg.svg';
import UserProfileImage from '../../../../components/UserProfileImage';
import { getAuth, signOut } from 'firebase/auth';
import { FC, SetStateAction, useContext, useState } from 'react';
import { Copy, CopyBold, CopySmall, CopySmallBold, Header2 } from '../TypographyFonts';
import { FixedColors } from 'theme';
import { ElyvateColors, UIContext } from 'UIProvider';
import { ShowcaseUserPageViewType } from 'ifaces';
import ShowcaseShareDialogBox from '../ShareDialog/ShowcaseShareDialogBox';
import { BYDataCtx } from 'DataProvider';
import { useNavigate } from 'react-router-dom';

const UserIconButton = styled(IconButton)({
  position: 'absolute',
  right: '0',
  top: '0',
  zIndex: 10,
});

interface ShowcaseFixedNavigationBarProps {
  checkedAppsToShare?: Array<string>;
  demoLink?: string | null;
  setShareDialogBoxOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowcaseFixedNavigationBar: FC<ShowcaseFixedNavigationBarProps> = ({
  checkedAppsToShare,
  demoLink,
  setShareDialogBoxOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  const { showcaseUserPageView } = useContext(BYDataCtx);

  const isBeryllsUserOverview = showcaseUserPageView === ShowcaseUserPageViewType.ADMIN_OVERVIEW;
  const isBeryllsUserDetail = showcaseUserPageView === ShowcaseUserPageViewType.ADMIN_DETAIL_VIEW;
  const isClientOverview = showcaseUserPageView === ShowcaseUserPageViewType.CLIENT_OVERVIEW;
  const isClientDetailView = showcaseUserPageView === ShowcaseUserPageViewType.CLIENT_DETAIL_VIEW;

  const { isMobile } = useContext(UIContext);

  const { projectColors } = useContext(UIContext);

  return (
    <Stack
      sx={{
        height: '64px',
        backgroundColor: 'white',
        px: isMobile ? 2 : 15,
        alignItems: 'center',
        py: 2,
        position: 'fixed',
        zIndex: 100,
        width: '100%',
      }}
    >
      <Stack
        maxWidth={'lg'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Stack
          sx={{
            width: '155px',
            top: '12px',
            height: '40px',
          }}
        >
          <IconButton disableRipple sx={{ p: 0 }} onClick={() => navigate('/')}>
            <BeryllsLogo />
          </IconButton>
        </Stack>
        {isBeryllsUserOverview ? (
          <>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleSignOut}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
            <Stack onClick={handleClick}>
              <UserProfileImage />
            </Stack>
          </>
        ) : null}

        {isBeryllsUserDetail ? (
          <Stack>
            <Stack direction="row" spacing={1}>
              {!isMobile && (
                <Button
                  sx={{
                    backgroundColor: FixedColors.white,
                    borderRadius: '8px',
                    border: `1px solid ${projectColors?.accent}`,
                    maxWidth: '98px',
                    maxHeight: '36px',
                    gap: '8px',
                    '&:hover': {},
                    padding: '8px 12px',
                    display: 'flex',
                  }}
                  onClick={() => setShareDialogBoxOpen && setShareDialogBoxOpen(true)}
                >
                  <SendOutlinedIcon
                    fontSize="small"
                    sx={{
                      color: projectColors?.accent,
                      transform: 'rotate(-40deg)',
                      height: '15px',
                      width: '15px',
                      mb: '3px',
                    }}
                  />
                  <CopySmallBold sx={{ textTransform: 'none', color: projectColors?.accent }}>Share</CopySmallBold>
                </Button>
              )}

              <Link
                href={demoLink ? `${demoLink}` : undefined}
                target={demoLink ? '_blank' : undefined}
                rel={demoLink ? 'noopener noreferrer' : undefined}
                sx={{
                  textDecoration: 'none',
                  pointerEvents: demoLink ? 'auto' : 'none',
                  cursor: demoLink ? 'pointer' : 'not-allowed',
                }}
              >
                <Button
                  sx={{
                    backgroundColor: projectColors?.accent,
                    borderRadius: '8px',
                    border: `1px solid ${projectColors?.accent}`,
                    maxWidth: '140px',
                    maxHeight: '36px',
                    gap: '8px',
                    '&:hover': {
                      backgroundColor: projectColors?.accent,
                    },
                    display: 'flex',
                    padding: '8px 12px',
                  }}
                >
                  <CopySmallBold
                    sx={{
                      color: FixedColors.white,
                      textTransform: 'none',
                    }}
                  >
                    {isMobile ? 'Demo' : 'View Demo'}
                  </CopySmallBold>
                </Button>
              </Link>
            </Stack>
          </Stack>
        ) : null}

        {/* {isClientOverview ? (
          <Stack sx={{ alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={handleSignOut}>
            <CopySmallBold>Logout</CopySmallBold>
          </Stack>
        ) : null} */}

        {isClientDetailView ? (
          <Stack>
            <Link
              href={demoLink ? `${demoLink}` : undefined}
              target={demoLink ? '_blank' : undefined}
              rel={demoLink ? 'noopener noreferrer' : undefined}
              sx={{
                textDecoration: 'none',
                pointerEvents: demoLink ? 'auto' : 'none',
                cursor: demoLink ? 'pointer' : 'not-allowed',
              }}
            >
              <Button
                sx={{
                  backgroundColor: projectColors?.accent,
                  borderRadius: '8px',
                  border: `1px solid ${projectColors?.accent}`,
                  maxWidth: '140px',
                  maxHeight: '36px',
                  gap: '8px',
                  '&:hover': {
                    backgroundColor: projectColors?.accent,
                  },
                  display: 'flex',
                  padding: '8px 12px',
                }}
              >
                <CopySmallBold sx={{ color: FixedColors.white, textTransform: 'none' }}>View Demo</CopySmallBold>
              </Button>
            </Link>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default ShowcaseFixedNavigationBar;

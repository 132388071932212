import { Grid, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import BlurredBannerBackground from 'pages/ShowcasePage/components/BlurredBannerBackground/BlurredBannerBackground';
import { FC } from 'react';

const CustomCard = styled('div')({
  borderRadius: '10px',
  boxShadow: 'rgb(32 54 86 / 15%) 0px 8px 20px',
  padding: '30px',
});

interface IndexPageCardProps {
  title: string;
  description: string;
}

const IndexPageCard: FC<IndexPageCardProps> = ({ title, description }) => {
  return (
    <Grid item md={6} sm={12} xs={12}>
      <CustomCard>
        <Typography
          sx={{
            fontSize: '1.2rem',
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </CustomCard>
    </Grid>
  );
};

export default function IndexPage() {
  return (
    <Stack
      sx={{
        flex: 1,
        position: 'relative',
      }}
    >
      <Stack
        sx={{
          flex: '100vh',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'Faktum-Bold',
            textTransform: 'uppercase',
            color: 'white',
            zIndex: 2,
            lineHeight: '28px',
          }}
        >
          What we did to step ahead
        </Typography>
        <Typography
          sx={{
            fontSize: '96px',
            fontFamily: 'Faktum-Bold',
            color: 'white',
            zIndex: 2,
            maxWidth: '700px',
            lineHeight: '88px',
            textAlign: 'center',
          }}
        >
          What we did to step ahead
        </Typography>
        <BlurredBannerBackground />
      </Stack>
      <Stack
        sx={{
          flex: '100vh',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* {apps.map(app => (
          <></>
        ))} */}
      </Stack>
      <Grid container spacing={3}>
        <IndexPageCard title="Projects" description="List of projects at BDV" />
        <IndexPageCard title="Staffing Overview" description="Overview of team staffing & capacity" />
      </Grid>
    </Stack>
  );
}

import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

interface DotProps {
  visible?: boolean; // Add the custom prop shadowColor
}

const Dot = styled('div')<DotProps>(({ theme, visible }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: 'white',
  margin: '0 8px',
  transition: 'opacity 0.6s ease-in-out', // Adding the transition property
  opacity: visible ? 1 : 0, // Setting the opacity based on the visibility state
}));

const LoaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

const BouncyDotLoader = () => {
  const [dot1Visible, setDot1Visible] = useState(false);
  const [dot2Visible, setDot2Visible] = useState(false);
  const [dot3Visible, setDot3Visible] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setDot1Visible(prev => !prev);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDot2Visible(prev => !prev);
    }, 400);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDot3Visible(prev => !prev);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <LoaderContainer>
      <Dot visible={dot1Visible} />
      <Dot visible={dot2Visible} />
      <Dot visible={dot3Visible} />
    </LoaderContainer>
  );
};

export default BouncyDotLoader;

import { animated, useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';

const LeftSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="970" height="613" viewBox="0 0 970 613" fill="none">
    <g opacity="0.6" filter="url(#filter0_f_1380_844)">
      <path
        d="M210.869 58.1839C501.376 225.909 896.874 115.88 729.338 406.06C561.803 696.239 218.823 875.141 -71.685 707.416C-362.193 539.691 -509.102 427.396 -341.567 137.216C-174.031 -152.963 -79.6391 -109.541 210.869 58.1839Z"
        fill="url(#paint0_linear_1380_844)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_1380_844"
        x="-612.155"
        y="-274.275"
        width="1581.44"
        height="1246.39"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_1380_844" />
      </filter>
      <linearGradient
        id="paint0_linear_1380_844"
        x1="-353.566"
        y1="157.997"
        x2="854.062"
        y2="855.221"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="#3B86B5" />
        <stop offset="1" stopColor="#3B86B5" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const RightSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="760" height="613" viewBox="0 0 760 613" fill="none">
    <g opacity="0.6" filter="url(#filter0_f_1380_843)">
      <path
        d="M1004.06 479.142C809.63 673.568 540.684 702.781 346.195 508.292C151.706 313.803 151.769 327.157 346.194 132.731C540.62 -61.6946 556.812 -166.9 751.301 27.5891C945.79 222.078 1198.48 284.717 1004.06 479.142Z"
        fill="url(#paint0_linear_1380_843)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_1380_843"
        x="0.351807"
        y="-275.878"
        width="1273.6"
        height="1116.26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_1380_843" />
      </filter>
      <linearGradient
        id="paint0_linear_1380_843"
        x1="826.463"
        y1="56.3117"
        x2="214.304"
        y2="668.471"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D76430" />
        <stop offset="1" stopColor="#D76430" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const getLeftPositionStyle = (positionIndex: number) => {
  const leftPositions = [
    {
      top: '448px',
      left: '-521px',
      transform: 'rotate(60deg)',
    },
    {
      top: '267px',
      left: '-115px',
      transform: 'rotate(15deg)',
    },
    {
      top: '721px',
      left: '-521px',
      transform: 'rotate(60deg)',
    },
  ];

  return leftPositions[positionIndex];
};

const getRightPositionStyle = (positionIndex: number) => {
  const rightPositions = [
    {
      top: '-150px',
      left: '1300px',
      transform: 'rotate(-45deg)',
    },
    {
      top: '690.7734375px',
      left: '1595px',
      transform: 'rotate(-165deg)',
    },
    {
      top: '54px',
      left: '1420px',
      transform: 'rotate(-45deg)',
    },
  ];

  return rightPositions[positionIndex];
};

const BlurredBackground = () => {
  const [positions, setPositions] = useState(0);

  const updatePositions = () => {
    setPositions(prevPositions => (prevPositions + 1) % 3);
  };

  useEffect(() => {
    const interval = setInterval(updatePositions, 1000);
    return () => clearInterval(interval);
  }, []);
  const leftGradient = useSpring({
    from: {
      background: 'linear-gradient(180deg, #3B86B5 0%, rgba(59, 134, 181, 0) 100%)',
      opacity: 0.6,
      blur: '100px',
      width: '716px',
      height: '750px',
      ...getLeftPositionStyle(positions),
    },
    // to: async next => {
    //   while (true) {
    //     await next({ opacity: 0.6, blur: '50px' }); // First set of opacity values
    //     await next({ opacity: 0.4, blur: '150px' }); // Second set of opacity values
    //     await next({ opacity: 0.6, blur: '100px' }); // Third set of opacity values
    //     await next({ opacity: 0.3, blur: '120px' }); // Fourth set of opacity values
    //     await next({ opacity: 0.7, blur: '70px' }); // Fifth set of opacity values
    //     await next({ opacity: 0.5, blur: '90px' }); // Sixth set of opacity values
    //     await next({ opacity: 0.6, blur: '80px' }); // Seventh set of opacity values
    //   }
    // },
    config: { duration: 4000 },
  });

  const rightGradient = useSpring({
    from: {
      background: 'linear-gradient(180deg, #D76430 0%, rgba(215, 100, 48, 0) 100%)',
      opacity: 0.6,
      blur: '100px',
      width: '716px',
      height: '659px',
      ...getRightPositionStyle(positions),
    },
    // to: async next => {
    //   while (true) {
    //     await next({ opacity: 0.8, blur: '60px' }); // First set of opacity values
    //     await next({ opacity: 0.4, blur: '130px' }); // Second set of opacity values
    //     await next({ opacity: 0.6, blur: '90px' }); // Third set of opacity values
    //     await next({ opacity: 0.3, blur: '110px' }); // Fourth set of opacity values
    //     await next({ opacity: 0.7, blur: '80px' }); // Fifth set of opacity values
    //     await next({ opacity: 0.5, blur: '100px' }); // Sixth set of opacity values
    //     await next({ opacity: 0.6, blur: '70px' }); // Seventh set of opacity values
    //   }
    // },
    config: { duration: 4000 },
  });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '700',
          height: '1061',
          transform: 'rotate(40deg)',
          flexShrink: 0,
        }}
      >
        <animated.div
          style={{
            ...leftGradient,
            flex: 1,
            background: leftGradient.opacity.to(
              o => `linear-gradient(180deg, #3B86B5 0%, rgba(59, 134, 181, ${o}) 100%)`,
            ),
            filter: leftGradient.blur.to(b => `blur(${b})`),
          }}
        >
          <LeftSVG />
        </animated.div>
      </div>
      <div style={{ background: 'white', width: '50px', flex: 0.3 }}></div>
      <div style={{ width: '720', height: '660', transform: 'rotate(40deg)', flexShrink: 0 }}>
        <animated.div
          style={{
            ...rightGradient,
            flex: 1,
            background: rightGradient.opacity.to(
              o => `linear-gradient(180deg, #D76430 0%, rgba(215, 100, 48, ${o}) 100%)`,
            ),
            filter: rightGradient.blur.to(b => `blur(${b})`),
          }}
        >
          <RightSVG />
        </animated.div>
      </div>
    </div>
  );
};

export default BlurredBackground;

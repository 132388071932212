// MUI
import { Grid, Stack } from '@mui/material';
import useWindowSize from 'components/hooks/useWindowSize';
import exampleImage from '../../../assets/example2.png';
import { CSSProperties, FC, useContext } from 'react';
import { Copy, Header1, Kicker } from '../components/TypographyFonts';
import ImageComponent from '../components/ImageComponent';
import { ShowcaseApp, ShowcaseTextImage } from 'ifaces';
import example3Image from '../../../assets/SampleProjectAssets/example3.png';
import React from 'react';
import { UIContext } from 'UIProvider';

export type textAndImage = {
  kickerText: string;
  title: string;
  description: string;
  // TODO: add image
};

interface RenderTextComponentProps {
  isMobile?: boolean;
  marginTop?: CSSProperties;
  item: textAndImage;
}

const RenderTextComponent: FC<RenderTextComponentProps> = ({ isMobile, marginTop, item }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={6}
      // maxWidth={'lg'}
      sx={{ marginTop: isMobile ? null : marginTop, paddingTop: isMobile ? null : '0px' }}
    >
      <Kicker isMobile={isMobile} sx={{ textTransform: 'uppercase' }}>
        {item.kickerText}
      </Kicker>
      <Header1 isMobile={isMobile}>{item.title}</Header1>
      <Copy isMobile={isMobile} sx={{ marginTop: 4 }}>
        {item.description}
      </Copy>
    </Grid>
  );
};

interface RenderImageComponentProps {
  isMobile?: boolean;
  marginTop?: CSSProperties;
  source: string;
  shadowColor?: string;
  extension?: string;
}

const RenderImageComponent: FC<RenderImageComponentProps> = ({
  isMobile,
  marginTop,
  source,
  shadowColor,
  extension,
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={6}
      sx={{
        marginTop: isMobile ? 3 : marginTop,
        marginBottom: isMobile ? 2 : null,
        paddingTop: isMobile ? null : '0px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <ImageComponent imageOrVideo={source} ContainerShadowColor={shadowColor} extension={extension} />
    </Grid>
  );
};

export interface ShowcaseTextImageBlockSectionProps {
  projectTextImage: ShowcaseTextImage;
}

const ShowcaseTextImageBlockSection: FC<ShowcaseTextImageBlockSectionProps> = ({ projectTextImage }) => {
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;
  const imageUrl = projectTextImage.textAndImage.image.data?.attributes.url;
  const imageExtension = projectTextImage.textAndImage.image.data?.attributes.ext;
  const { projectColors } = useContext(UIContext);

  return (
    <Stack
      sx={{
        paddingTop: isMobile ? 4 : 10,
        paddingBottom: isMobile ? 4 : 10,
        paddingLeft: isMobile ? 2 : 15,
        paddingRight: isMobile ? 2 : 15,
        margin: 'auto',
      }}
    >
      <Grid
        container
        spacing={isMobile ? 'unset' : 7}
        sx={{ display: 'flex', flex: 1, alignItems: 'center', margin: 'auto' }}
        maxWidth={'lg'}
      >
        {isMobile ? (
          <>
            <React.Fragment>
              <RenderTextComponent isMobile={isMobile} item={projectTextImage.textAndImage} />
              <RenderImageComponent isMobile={isMobile} source={imageUrl} extension={imageExtension} />
            </React.Fragment>
          </>
        ) : (
          <>
            {projectTextImage.textAndImage.rightImagePosition === false ? (
              <>
                <RenderImageComponent
                  isMobile={isMobile}
                  source={imageUrl}
                  shadowColor={projectColors?.shadowInRgba}
                  extension={imageExtension}
                />
                <RenderTextComponent isMobile={isMobile} item={projectTextImage.textAndImage} />
              </>
            ) : (
              <>
                <RenderTextComponent isMobile={isMobile} item={projectTextImage.textAndImage} />
                <RenderImageComponent
                  isMobile={isMobile}
                  source={imageUrl}
                  shadowColor={projectColors?.shadowInRgba}
                  extension={imageExtension}
                />
              </>
            )}
          </>
        )}
      </Grid>
    </Stack>
  );
};

export default ShowcaseTextImageBlockSection;

// MUI
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Card, Divider, Grid, Link, Stack } from '@mui/material';
import { getShowcaseProjects } from 'API';
import { UIContext } from 'UIProvider';
import useWindowSize from 'components/hooks/useWindowSize';
import { ShowcaseProject } from 'ifaces';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Copy, CopyBold, Header1, Kicker } from '../components/TypographyFonts';

// ShowcaseDemoAndContact.tsx
type ShowcaseFooterNextProjectSectionProps = {
  project: ShowcaseProject | undefined;
};

const ShowcaseFooterNextProjectSection: React.FC<ShowcaseFooterNextProjectSectionProps> = ({ project }) => {
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;
  const image = project?.header?.image?.data?.attributes?.url;
  const [nextProject, setNextProject] = useState<ShowcaseProject | undefined>(undefined);

  const navigate = useNavigate();

  const { projectColors } = useContext(UIContext);

  // find the next project
  useEffect(() => {
    const fetchNextProject = async () => {
      try {
        const projects = await getShowcaseProjects();
        // find the array index of the current project
        if (project) {
          const projectIndex = projects.findIndex(item => item.project_id === project.project_id);
          // find projectindex +1 in the projects
          const nextProject = projects[projectIndex];
          setNextProject(nextProject);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchNextProject();
  }, [project]);

  return (
    <Stack
      sx={{
        paddingTop: isMobile ? 2 : 3,
        px: isMobile ? 2 : 15,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <Stack maxWidth={'lg'} sx={{ width: '100%' }}>
        <Divider orientation="horizontal" sx={{ backgroundColor: '#E3E3E3', marginBottom: isMobile ? 2 : 3 }} />
        <Stack flexDirection={'row'}>
          {!isMobile && (
            <Link
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                  textDecorationColor: '#000000',
                },
              }}
              href={'/'}
            >
              <Copy isMobile={isMobile}>Alle Projekte anzeigen</Copy>
            </Link>
          )}
          {nextProject && (
            <Link
              sx={{
                textDecoration: 'none',
                display: 'flex',
                flex: 1,
                justifyContent: isMobile ? null : 'flex-end',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                  textDecorationColor: '#000000',
                },
              }}
              href={'/projects/' + nextProject.project_id}
            >
              <CopyBold isMobile={isMobile}>Next Project</CopyBold>
            </Link>
          )}
        </Stack>
        <Kicker isMobile={isMobile} sx={{ textTransform: 'uppercase', marginTop: isMobile ? 5 : 10 }}>
          {project?.header?.kickerText}
        </Kicker>
        <Stack flexDirection={isMobile ? 'column' : 'row'} sx={{ marginBottom: 5 }}>
          <Header1 isMobile={isMobile} sx={{ maxWidth: '588px' }}>
            {project?.header?.title}
          </Header1>
          <div
            style={{
              justifyContent: isMobile ? 'unset' : 'flex-end',
              display: 'flex',
              flex: 1,
              marginTop: isMobile ? '20px' : 'unset',
            }}
          >
            <Copy isMobile={isMobile} sx={{ maxWidth: '486px' }}>
              {project?.header?.description}
            </Copy>
          </div>
        </Stack>
        <Card
          sx={{
            border: '1px solid #E3E3E3',
            height: isMobile ? '133px' : '200px',
            borderRadius: '12px 12px 0px 0px',
            display: 'flex',
            flexDirection: 'column',
            marginTop: isMobile ? 2 : 5,
            filter: `drop-shadow(0px 76px 150px ${projectColors?.shadowInRgba ?? 'rgba(0, 0, 0, 0.25)'})`,
          }}
        >
          <Box
            sx={{
              alignSelf: 'center',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${image})`,
              // Background Image should be at the middle bottom
              backgroundPosition: 'center top',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              filter: `drop-shadow(0px 76px 150px ${projectColors?.shadowInRgba ?? 'rgba(0, 0, 0, 0.05)'})`,
            }}
          />
        </Card>
        {isMobile && (
          <Stack flexDirection={'row'} sx={{ display: 'flex', alignItems: 'center' }} onClick={() => navigate('/')}>
            <ArrowBackIosNewIcon></ArrowBackIosNewIcon>
            <Copy isMobile={isMobile} sx={{ marginTop: 1.5, marginBottom: 1.5, marginLeft: 2.5 }}>
              Show Project Overview
            </Copy>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default ShowcaseFooterNextProjectSection;

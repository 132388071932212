import { FC } from 'react';

// MUI
import { Box } from '@mui/material';

// Custom Components
export interface MainLayoutProps {}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <Box maxWidth={undefined} sx={{ margin: 0, padding: 0, position: 'relative', flex: 1 }}>
      {children}
    </Box>
  );
};

export default MainLayout;

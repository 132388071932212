import { Container, Paper, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { getAllUsers, setUserDivision, setUserPermissions } from '../../API';
import { SystemFirebaseUser } from '../../ifaces';

const SYSTEM_PERMISSIONS = ['SKILLS', 'BDV', 'OKR', 'AHB', 'AUTO100', 'SUR', 'SDV', 'SUPPLIERFOOTPRINT'];
const DIVISIONS = ['BSA', 'BDV', 'BMM', 'BEQ'];

const TableHeader = () => {
  return (
    <Stack direction={'row'} mt={2} sx={{ fontWeight: 600, fontSize: '0.8rem' }} spacing={2}>
      <Stack style={{ flex: 1, justifyContent: 'center' }}>UID</Stack>
      <Stack style={{ flex: 1, justifyContent: 'center' }}>Name</Stack>
      <Stack style={{ width: '250px', justifyContent: 'center', alignItems: 'center' }}>Permisisions</Stack>
      <Stack style={{ width: '200px', justifyContent: 'center', alignItems: 'center' }}>Division</Stack>
    </Stack>
  );
};

interface TableRowProps {
  fetchData: () => void;
  user: SystemFirebaseUser;
}

const TableRow: FC<TableRowProps> = ({ user, fetchData }) => {
  let [permissions, setPermissions] = useState<string[]>([]);
  let [division, setDivision] = useState<string>();

  useEffect(() => {
    if (user.customClaims && user.customClaims.permissions) {
      const permissions = user.customClaims.permissions.split(',');
      setPermissions(permissions);
    }
    if (user.customClaims && user.customClaims.division) {
      setDivision(user.customClaims.division);
    }
  }, [user]);

  return (
    <Stack direction={'row'} sx={{ fontWeight: 400, fontSize: '0.8rem' }} spacing={2}>
      <Stack style={{ flex: 1, justifyContent: 'center' }}>{user.uid}</Stack>
      <Stack style={{ flex: 1, justifyContent: 'center' }}>{user.email}</Stack>
      <Stack direction={'row'} style={{ width: '250px', justifyContent: 'center', alignItems: 'center' }}>
        {SYSTEM_PERMISSIONS.map(systemPermission => {
          const hasPermission = permissions.indexOf(systemPermission) !== -1;
          return (
            <span
              key={'permission_' + systemPermission}
              style={{
                color: hasPermission ? 'blue' : 'crimson',
                marginLeft: '5px',
                cursor: 'pointer',
              }}
              onClick={async () => {
                if (hasPermission) {
                  await setUserPermissions(
                    user.uid,
                    permissions.filter(p => p !== systemPermission),
                  );
                } else {
                  let newPermissions: string[] = permissions;
                  newPermissions.push(systemPermission);
                  await setUserPermissions(user.uid, newPermissions);
                }
                fetchData();
              }}
            >
              {systemPermission}
            </span>
          );
        })}
      </Stack>
      <Stack direction={'row'} style={{ width: '200px', justifyContent: 'center', alignItems: 'center' }}>
        {DIVISIONS.map((d, i) => {
          return (
            <span
              key={'division_option_' + i}
              style={{
                color: d === division ? 'blue' : 'crimson',
                marginLeft: '5px',
                cursor: 'pointer',
              }}
              onClick={async () => {
                await setUserDivision(user.uid, d);
                fetchData();
              }}
            >
              {d}
            </span>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default function AdminPage() {
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState<SystemFirebaseUser[]>();

  const fetchData = async () => {
    setLoading(true);
    const users = await getAllUsers();
    if (users !== null) setUsers(users);
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <Container maxWidth={'lg'} component={Stack} spacing={2} py={2}>
        <Paper sx={{ p: 5 }}>
          <Typography variant="h6">Loading...</Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth={'lg'} component={Stack} spacing={2} py={2}>
      <Stack
        sx={{
          position: 'relative',
          flex: 1,
          borderRadius: '15px',
          background: 'white',
          filter: 'drop-shadow(0px 0px 5px #ECECEC)',
          padding: '30px',
        }}
      >
        <Typography variant="h5">Users</Typography>
        <Stack spacing={1}>
          <TableHeader />
          {users &&
            users.map((user, index) => {
              return <TableRow key={'user_' + index} user={user} fetchData={fetchData} />;
            })}
        </Stack>
      </Stack>
    </Container>
  );
}

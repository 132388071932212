// MUI
import { Box, BoxProps, Grid, Stack, styled } from '@mui/material';
import { FixedColors } from 'theme';
import { Copy, CopyBold, Header1, Kicker } from '../components/TypographyFonts';
import { FC, useContext } from 'react';
import Vector from '../../../assets/IconAsset.svg';
import useWindowSize from 'components/hooks/useWindowSize';
import { ShowcaseApp, ShowcaseFeatureOverview } from 'ifaces';
import { UIContext } from 'UIProvider';
import IconImageComponent from '../components/IconImageComponent';

interface SquaredBoxProps extends BoxProps {
  shadowColor?: string; // Add the custom prop shadowColor
}

const SquaredBox = styled(Box)<SquaredBoxProps>(({ theme, shadowColor }) => ({
  minWidth: '78px',
  height: '78px',
  borderRadius: '24px',
  backgroundColor: FixedColors.white,
  border: '1px solid #E3E3E3',
  marginRight: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  filter: `drop-shadow(0px 12px 20px ${shadowColor ?? 'rgba(0, 0, 0, 0.05)'})`,
}));

const CenteredGridView = styled(Grid)(({ theme }) => ({
  display: 'flex',
  // flex: 1,
  flexDirection: 'row',
  marginBottom: '40px',
}));

const ContentStackView = styled(Stack)(({ theme }) => ({
  flex: 2,
}));

export type ShowcaseFeatureOverviewSectionProps = {
  projectFeatureOverview: ShowcaseFeatureOverview;
};

const ShowcaseFeatureOverviewSection: FC<ShowcaseFeatureOverviewSectionProps> = ({ projectFeatureOverview }) => {
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;
  const { projectColors } = useContext(UIContext);
  return (
    <Stack
      sx={{
        position: 'relative',
        paddingTop: isMobile ? 5 : 10,
        paddingBottom: isMobile ? 5 : 10,
        paddingLeft: isMobile ? 2 : 10,
        paddingRight: isMobile ? 2 : 10,
        margin: 'auto',
      }}
    >
      <Stack sx={{ width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <Stack sx={{ maxWidth: isMobile ? 'unset' : '1200px' }}>
          <Kicker isMobile={isMobile} sx={{ textAlign: 'center', marginBottom: 2 }}>
            {projectFeatureOverview.kickerText}
          </Kicker>
          <Header1 isMobile={isMobile} sx={{ textAlign: 'center' }}>
            {projectFeatureOverview.title}
          </Header1>
        </Stack>

        <Grid
          container
          spacing={1}
          sx={{
            marginTop: isMobile ? '40px' : '80px',
            justifyContent: 'center',
            display: 'flex',
            maxWidth: isMobile ? 'unset' : '1200px',
          }}
        >
          {projectFeatureOverview.feature.map((feature, index) => (
            <CenteredGridView item xs={12} sm={12} md={4} lg={4} xl={4} key={index} spacing={4} sx={{}}>
              <SquaredBox shadowColor={projectColors?.shadowInRgba}>
                <IconImageComponent
                  image={feature.icon?.data ? feature.icon?.data.attributes.url : Vector}
                  iconColor={projectColors?.icons}
                />
              </SquaredBox>
              <ContentStackView>
                <CopyBold isMobile={isMobile} sx={{ color: FixedColors.almostBlack }}>
                  {feature.name}
                </CopyBold>
                <Copy isMobile={isMobile} sx={{ color: FixedColors.almostBlack }}>
                  {feature.description}
                </Copy>
              </ContentStackView>
            </CenteredGridView>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ShowcaseFeatureOverviewSection;

// MUI
import { Box, Stack } from '@mui/material';
import useWindowSize from 'components/hooks/useWindowSize';
import { FC, useContext, useEffect, useState } from 'react';
import exampleImage from '../../../assets/example3.png';
import { Copy, Header1, Kicker } from '../components/TypographyFonts';
import ImageComponent from '../components/ImageComponent';
import { ShowcaseAnimation, ShowcaseApp } from 'ifaces';
import example3Image from '../../../assets/example3.png';
import { UIContext } from 'UIProvider';

interface ImageComponentProps {
  image: string;
  isMobile?: boolean;
  ContainerShadowColor?: string;
  enableAnimation?: boolean;
  extension?: string;
}

const ImageAnimationComponent: FC<ImageComponentProps> = ({
  image,
  isMobile,
  ContainerShadowColor,
  enableAnimation,
  extension,
}) => {
  const [slideOffset, setSlideOffset] = useState(0);
  const slideThreshold = isMobile ? -500 : -1000; // Adjust the threshold to control when to reset the slide offset

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideOffset(prevOffset => {
        const newOffset = prevOffset - 1;
        // Check if the offset reaches the threshold
        if (newOffset <= slideThreshold) {
          // Reset the offset to start sliding from the beginning
          if (isMobile) {
            return 500;
          } else {
            return 1000;
          }
        }
        return newOffset;
      });
    }, 3); // Adjust the interval duration to control the sliding speed
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ImageComponent
      imageOrVideo={image}
      ImageStyle={{ transform: isMobile && enableAnimation ? `translateX(${slideOffset}px)` : undefined }}
      ContainerShadow={false}
      ContainerShadowColor={ContainerShadowColor}
      extension={extension}
    />
  );
};

interface ShowcaseTextImageAnimationSectionProps {
  projectAnimation: ShowcaseAnimation;
}

const ShowcaseTextImageAnimationSection: FC<ShowcaseTextImageAnimationSectionProps> = ({ projectAnimation }) => {
  const { width } = useWindowSize();
  const isMobile = width !== undefined ? width < 600 : undefined;
  // ImageUrl
  const imageUrl = projectAnimation.media.image.data.attributes.url;
  const { projectColors } = useContext(UIContext);

  return (
    <Stack
      sx={{
        paddingTop: isMobile ? 4 : 15,
        paddingBottom: isMobile ? 4 : 10,
        paddingLeft: 2,
        paddingRight: 2,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <Stack
        sx={{
          maxWidth: isMobile ? 'unset' : '1200px',
          zIndex: 2,
          width: '100%',
        }}
      >
        <Box
          sx={{
            paddingLeft: isMobile ? null : 0,
            paddingRight: isMobile ? null : 0,
            justifyContent: 'center',
            display: 'flex',
            mb: 11,
          }}
        >
          <Stack sx={{ maxWidth: isMobile ? 'unset' : '792px' }}>
            <Kicker isMobile={isMobile} sx={{ textTransform: 'uppercase' }}>
              {projectAnimation.kickerText}
            </Kicker>
            <Header1 isMobile={isMobile}>{projectAnimation.title}</Header1>
            <Copy isMobile={isMobile} sx={{ marginTop: '20px', marginBottom: '46px' }}>
              {projectAnimation.description}
            </Copy>
          </Stack>
        </Box>
        <Stack
          sx={{
            width: '100%',

            zIndex: 2,
          }}
        >
          <ImageAnimationComponent
            image={imageUrl}
            isMobile={isMobile}
            ContainerShadowColor={projectColors?.shadowInRgba}
            enableAnimation={projectAnimation.enableAnimation}
            extension={projectAnimation.media.image.data.attributes.ext}
          />
        </Stack>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            backgroundImage: `linear-gradient(180deg, ${projectColors?.gradientInHex} 0%, ${projectColors?.gradientInRgba} 100%)`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ShowcaseTextImageAnimationSection;

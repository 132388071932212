// MUI
import { Box, Stack } from '@mui/material';
import { FC, useContext } from 'react';
import { Copy, CopyBold, CopySmall } from '../TypographyFonts';
import ShareDialogButton from './ShareDialogButton';
import { UIContext } from 'UIProvider';

interface Step1InviteeNameProps {
  inviteCode: string;
  onInviteTokenCopied?: () => void;
}

export const Step2CopyInviteToken: FC<Step1InviteeNameProps> = ({ inviteCode, onInviteTokenCopied }) => {
  const { isMobile } = useContext(UIContext);
  const handleCopyInfoClick = () => {
    if (inviteCode.trim() === '') {
      return;
    }
    const copyText = `You now have access to a personally for you curated\n showcase of Berylls Digital Ventures. If you have any\n questions, don't hesitate to contact me.

    Please be aware that your link and password are\n specifically curated for you and should not be shared\n with anyone else.
    
    Link: https://berylls.app\n
    Password: ${inviteCode}
    
    * Note that your showcase is only valid for 30 days.`;

    // Copy the password to the clipboard
    navigator.clipboard.writeText(copyText);

    if (onInviteTokenCopied) {
      onInviteTokenCopied();
    }
  };

  return (
    <Stack sx={{}}>
      <CopyBold>Showcase information</CopyBold>
      <Copy sx={{ mb: 2 }}>
        Simply copy the password and link and paste them into your email or presentation. Please keep in mind that all
        links are only valid for 30 days.
      </Copy>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          p: 2,
          // gap: 2,
          borderRadius: 1,
          backgroundColor: '#F5F5F5',
          mb: 2,
        }}
      >
        <CopySmall sx={{ opacity: 0.6 }}>Link</CopySmall>
        <Copy sx={{ mb: 2 }}>https://berylls.app</Copy>
        <CopySmall sx={{ opacity: 0.6 }}>Password</CopySmall>
        {isMobile ? (
          <div style={{ width: '200px', height: '42px', display: 'flex', flexWrap: 'wrap' }}>
            <CopyBold sx={{ mb: 2, wordBreak: 'break-all' }}>{inviteCode}</CopyBold>
          </div>
        ) : (
          <CopyBold sx={{ mb: 2 }}>{inviteCode}</CopyBold>
        )}
      </Box>
      <ShareDialogButton onClick={handleCopyInfoClick}>Copy Info</ShareDialogButton>
    </Stack>
  );
};

export default Step2CopyInviteToken;

import { geoEqualEarth } from 'd3-geo';

export const projection = geoEqualEarth()
  .scale(160)
  .translate([800 / 2, 450 / 2]);

/**
 * Get extension from filename
 * @param extension: string
 * @return string
 */
export const getExtension = (extension: string): string => {
  //  pluck out summary object and sentence array
  return (extension.split('.') ?? []).pop() ?? '';
};

////////////////////////////////////////////////////////////////////////
////                             COOKIES                            ////
////////////////////////////////////////////////////////////////////////

export const setCookie = (name: string, value: unknown, days: number) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = (name: string) => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

////////////////////////////////////////////////////////////////////////
////                           DATE UTILS                           ////
////////////////////////////////////////////////////////////////////////

/**
 *
 * https://github.com/commenthol/weeknumber/blob/master/src/index.js
 *
 * ISO 8601 week numbering.
 *
 * New week starts on mondays.
 * Used by most European countries, most of Asia and Oceania.
 *
 * 1st week contains 4-7 days of the new year
 * @param {Date} [date] - local date
 * @return {number} week number in ISO 8601 format
 * @example
 * weekNumber(new Date(2016, 0, 3, 12)) // Sun
 * //> 53
 * weekNumber(new Date(2016, 0, 4, 12)) // Mon
 * //> 1
 */
export const getWeekNumber = (date = new Date()): number => {
  const MINUTE = 60000;
  const WEEK = 604800000; // = 7 * 24 * 60 * 60 * 1000 = 7 days in ms

  /**
   * Get the difference in milliseconds between the timezone offsets of 2 dates
   */
  const tzDiff = (first: Date, second: Date) => (first.getTimezoneOffset() - second.getTimezoneOffset()) * MINUTE;

  // day 0 is monday
  const day = (date.getDay() + 6) % 7;
  // get thursday of present week
  const thursday = new Date(date);
  thursday.setDate(date.getDate() - day + 3);
  // set 1st january first
  const firstThursday = new Date(thursday.getFullYear(), 0, 1);
  // if Jan 1st is not a thursday...
  if (firstThursday.getDay() !== 4) {
    firstThursday.setMonth(0, 1 + ((11 /* 4 + 7 */ - firstThursday.getDay()) % 7));
  }
  const weekNumber =
    1 + Math.floor((thursday.valueOf() - firstThursday.valueOf() + tzDiff(firstThursday, thursday)) / WEEK);
  return weekNumber;
};

/**
 *
 * https://github.com/commenthol/weeknumber/blob/master/src/index.js
 *
 * ISO 8601 calendar weeks in a given year
 *
 * New week starts on mondays.
 * Used by most European countries, most of Asia and Oceania.
 *
 * @param {number} year
 * @returns {number} weeks in year
 */
export const weeksPerYear = (year: number) => {
  let weeks = getWeekNumber(new Date(year, 11, 31, 12));
  if (weeks === 1) {
    weeks = getWeekNumber(new Date(year, 11, 31 - 3, 12));
  }
  return weeks;
};

/**
 * To calculate the date of the start of a given ISO8601 week
 * (which will always be a Monday)
 *
 * @param week
 * @param year
 * @returns
 */
export function getDateOfISOWeek(week: number, year: number): Date {
  var simple = new Date(year, 0, 1 + (week - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

// Calculate the HSL Value from Hex value

export const hexToHSL = (hex: string): string => {
  // Remove the leading '#' character and convert to RGB values
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;

  // Calculate the maximum and minimum values of RGB
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate the hue
  let h: number;
  if (max === min) {
    h = 0; // No hue, as it's a shade of gray
  } else if (max === r) {
    h = ((g - b) / (max - min) + 6) % 6;
  } else if (max === g) {
    h = (b - r) / (max - min) + 2;
  } else {
    h = (r - g) / (max - min) + 4;
  }

  // Convert hue to degrees
  const hue = Math.round(h * 60);

  // Calculate the lightness
  const l = (max + min) / 2;

  // Calculate the saturation
  let s: number;
  if (max === min) {
    s = 0; // No saturation, as it's a shade of gray
  } else {
    s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);
  }

  // Convert saturation and lightness to percentage
  const saturation = Math.round(s * 100);
  const lightness = Math.round(l * 100);

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

interface HSLColor {
  h: number;
  s: number;
  l: number;
}

export interface ColorSet {
  accentLight: HSLColor;
  accentDark: HSLColor;
  icon: HSLColor;
}

//  Parse HSL String to HSL Object

export const parseHSL = (hsl: string): HSLColor | null => {
  const hslRegex = /^hsl\(\s*(\d+)\s*,\s*(\d+%)\s*,\s*(\d+%)\s*\)$/i;
  const match = hsl.match(hslRegex);

  if (match) {
    const h = parseInt(match[1], 10);
    const s = parseInt(match[2], 10);
    const l = parseInt(match[3], 10);

    return { h, s, l };
  }

  return null; // Invalid HSL format
};

export const adjustHSL = (color: HSLColor): ColorSet => {
  const accentLightColor: HSLColor = { ...color, l: 95 };
  const accentDarkColor: HSLColor = { ...color, l: 13 };
  const iconColor: HSLColor = { ...color };

  return {
    accentLight: accentLightColor,
    accentDark: accentDarkColor,
    icon: iconColor,
  };
};

// Convert Hex to RGB
export function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  // Check if the provided hex value is valid
  const hexPattern = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const match = hex.match(hexPattern);

  if (!match) {
    console.error('Invalid hex color format. Please provide a valid hex color.');
    return null;
  }

  // Extract the RGB values from the matched groups and convert them to decimal
  const r = parseInt(match[1], 16);
  const g = parseInt(match[2], 16);
  const b = parseInt(match[3], 16);

  return { r, g, b };
}

// Convert HSL to Hex
export function hslToHex(h: number, s: number, l: number): string {
  // Ensure that H, S, and L values are within valid ranges
  const normalizedH = ((h % 360) + 360) % 360;
  const normalizedS = Math.max(0, Math.min(100, s));
  const normalizedL = Math.max(0, Math.min(100, l));

  // Convert HSL to RGB
  const c = (1 - Math.abs((2 * normalizedL) / 100 - 1)) * (normalizedS / 100);
  const x = c * (1 - Math.abs(((normalizedH / 60) % 2) - 1));
  const m = normalizedL / 100 - c / 2;

  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= normalizedH && normalizedH < 60) {
    r = c;
    g = x;
  } else if (60 <= normalizedH && normalizedH < 120) {
    r = x;
    g = c;
  } else if (120 <= normalizedH && normalizedH < 180) {
    g = c;
    b = x;
  } else if (180 <= normalizedH && normalizedH < 240) {
    g = x;
    b = c;
  } else if (240 <= normalizedH && normalizedH < 300) {
    r = x;
    b = c;
  } else if (300 <= normalizedH && normalizedH < 360) {
    r = c;
    b = x;
  }

  // Convert RGB to integers in the range [0, 255]
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  // Convert integers to hexadecimal format
  const componentToHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  const hexR = componentToHex(r);
  const hexG = componentToHex(g);
  const hexB = componentToHex(b);

  return `#${hexR}${hexG}${hexB}`;
}

export function rgbToHsb(rgb: { r: number; g: number; b: number }): { h: number; s: number; b: number } {
  const { r, g, b } = rgb;
  const rPrime = r / 255;
  const gPrime = g / 255;
  const bPrime = b / 255;
  const max = Math.max(rPrime, gPrime, bPrime);
  const min = Math.min(rPrime, gPrime, bPrime);
  const delta = max - min;

  let h = 0;
  let s = max === 0 ? 0 : (delta / max) * 100;
  let v = max * 100;

  if (delta !== 0) {
    if (max === rPrime) {
      h = 60 * (((gPrime - bPrime) / delta + 6) % 6);
    } else if (max === gPrime) {
      h = 60 * ((bPrime - rPrime) / delta + 2);
    } else if (max === bPrime) {
      h = 60 * ((rPrime - gPrime) / delta + 4);
    }
  }

  h = Math.round(h);
  s = Math.round(s);
  v = Math.round(b);

  return { h, s, b };
}

export function adjustHsbValue(hsb: { h: number; s: number; b: number }): { h: number; s: number; b: number } {
  const { h, s } = hsb;
  const adjustedS = Math.round(s / 16); // Divide the base of saturation by 16
  const adjustedB = 100; // Set brightness to 100

  return { h, s: adjustedS, b: adjustedB };
}

export function hsbToHex(h: number, s: number, brightness: number): string {
  const sPercentage = s / 100;
  const bPercentage = brightness / 100;

  const c = bPercentage * sPercentage;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = bPercentage - c;

  let r1, g1, b1;
  if (h >= 0 && h < 60) {
    [r1, g1, b1] = [c, x, 0];
  } else if (h >= 60 && h < 120) {
    [r1, g1, b1] = [x, c, 0];
  } else if (h >= 120 && h < 180) {
    [r1, g1, b1] = [0, c, x];
  } else if (h >= 180 && h < 240) {
    [r1, g1, b1] = [0, x, c];
  } else if (h >= 240 && h < 300) {
    [r1, g1, b1] = [x, 0, c];
  } else {
    [r1, g1, b1] = [c, 0, x];
  }

  const r = Math.round((r1 + m) * 255);
  const g = Math.round((g1 + m) * 255);
  const b = Math.round((b1 + m) * 255);

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

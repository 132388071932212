import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import RajdhaniMedium from './assets/fonts/Rajdhani-Medium.ttf';
import RajdhaniBold from './assets/fonts/Rajdhani-Bold.ttf';
// import FaktumRegular from './assets/fonts/Faktum-regular.otf';
// import FaktumBoldWoff from './assets/fonts/NeueFaktum-Bold.woff';
// import FaktumBoldWoff2 from './assets/fonts/NeueFaktum-Bold.woff2';
// import FaktumBoldItalicWoff from './assets/fonts/NeueFaktum-BoldItalic.woff';
// import FaktumBoldItalicWoff2 from './assets/fonts/NeueFaktum-BoldItalic.woff2';
// import FaktumExtraBoldWoff from './assets/fonts/NeueFaktum-ExtraBold.woff';
// import FaktumExtraBoldWoff2 from './assets/fonts/NeueFaktum-ExtraBold.woff2';
// import FaktumExtraBoldItalicWoff from './assets/fonts/NeueFaktum-ExtraBoldItalic.woff';
// import FaktumExtraBoldItalicWoff2 from './assets/fonts/NeueFaktum-ExtraBoldItalic.woff2';
// import FaktumExtraLightWoff from './assets/fonts/NeueFaktum-ExtraLightItalic.woff';
// import FaktumExtraLightWoff2 from './assets/fonts/NeueFaktum-ExtraLightItalic.woff2';
// import FaktumExtraLightItalicWoff from './assets/fonts/NeueFaktum-ExtraLightItalic.woff';
// import FaktumExtraLightItalicWoff2 from './assets/fonts/NeueFaktum-ExtraLightItalic.woff2';
// import FaktumLightWoff2 from './assets/fonts/NeueFaktum-Light.woff2';
// import FaktumLightWoff from './assets/fonts/NeueFaktum-Light.woff';
// import FaktumLightItalicWoff2 from './assets/fonts/NeueFaktum-LightItalic.woff2';
// import FaktumLightItalicWoff from './assets/fonts/NeueFaktum-LightItalic.woff';
// import FaktumMediumWoff2 from './assets/fonts/NeueFaktum-Medium.woff2';
// import FaktumMediumWoff from './assets/fonts/NeueFaktum-Medium.woff';
// import FaktumMediumItalicWoff2 from './assets/fonts/NeueFaktum-MediumItalic.woff2';
// import FaktumMediumItalicWoff from './assets/fonts/NeueFaktum-MediumItalic.woff';
// import FaktumRegularWoff2 from './assets/fonts/NeueFaktum-Regular.woff2';
// import FaktumRegularWoff from './assets/fonts/NeueFaktum-Regular.woff';
// import FaktumRegularItalicWoff2 from './assets/fonts/NeueFaktum-RegularItalic.woff2';
// import FaktumRegularItalicWoff from './assets/fonts/NeueFaktum-RegularItalic.woff';
// import FaktumSemiBoldWoff2 from './assets/fonts/NeueFaktum-SemiBold.woff2';
// import FaktumSemiBoldWoff from './assets/fonts/NeueFaktum-SemiBold.woff';
// import FaktumSemiBoldItalicWoff2 from './assets/fonts/NeueFaktum-SemiBoldItalic.woff2';
// import FaktumSemiBoldItalicWoff from './assets/fonts/NeueFaktum-SemiBoldItalic.woff';
// import FaktumThinWoff2 from './assets/fonts/NeueFaktum-Thin.woff2';
// import FaktumThinWoff from './assets/fonts/NeueFaktum-Thin.woff';
// import FaktumThinItalicWoff2 from './assets/fonts/NeueFaktum-ThinItalic.woff2';
// import FaktumThinItalicWoff from './assets/fonts/NeueFaktum-ThinItalic.woff';

let theme = createTheme({
  typography: {
    fontFamily: ['Rajdhani', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Rajdhani';
          font-style: normal;
          font-weight: 500;
          src: local('Rajdhani'), local('Rajdhani-Medium'), url(${RajdhaniMedium}) format('truetype');
          color: #000000;
        }
        @font-face {
          font-family: 'Rajdhani';
          font-style: normal;
          font-weight: 700;
          src: local('Rajdhani'), local('Rajdhani-Bold'), url(${RajdhaniBold}) format('truetype');
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;

// --------------------------FIXED COLORS--------------------------//
export const FixedColors = {
  almostBlack: '#080808',
  strokes: '#E3E3E3',
  inputField: '#F5F5F5',
  white: '#FFFFFF',
  blue: '#0000DB',
};

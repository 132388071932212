// MUI
import { Stack } from '@mui/material';
import BlurredBannerBackground from '../components/BlurredBannerBackground/BlurredBannerBackground';
import { BigTitle, Kicker } from '../components/TypographyFonts';
import useStickyScroll from 'components/hooks/useStickyScroll';
import { FC, useContext, useEffect, useRef, useState, MouseEvent } from 'react';
import ShowcaseHeaderButton from './components/ShowcaseHeaderButton';
import ShowcaseHeaderShareButton from './components/ShowcaseHeaderShareButton';
import ShowcaseHeaderDisplayModeButton from './components/ShowcaseHeaderDisplayModeButton';
import ShowcaseNavigationBar from '../components/ShowcaseNavigationBar/ShowcaseNagivationBar';
import useWindowSize from 'components/hooks/useWindowSize';
import useSize from 'components/hooks/useSize';
import { AuthCtx } from 'AuthProvider';
import { BYDataCtx } from 'DataProvider';
import { getShowcaseProjects } from 'API';
import { ShowcaseProject } from 'ifaces';
import ShowcaseFixedNavigationBar from '../components/ShowcaseFixedNavigationBar/ShowcaseFixedNavigationBar';

interface ShowcaseMainHeaderSectionProps {
  onShareButtonClick?: () => void;
  projectsToShare?: number;
  setGridOrListView: (mode: 'grid' | 'list') => void;
  headerOptionsVisible?: boolean;
}

type ProjectType = 'all' | 'elyvate suite' | 'dashboards' | 'konfiguratoren' | 'oem-software';

const ShowcaseMainHeaderSection: FC<ShowcaseMainHeaderSectionProps> = ({
  onShareButtonClick,
  projectsToShare,
  setGridOrListView,
  headerOptionsVisible,
}) => {
  const STICKY_SCROLL_OFFSET = 68;

  // Ref to keep track of the filters section height
  const filtersRef = useRef<HTMLDivElement | null>(null);
  const [currentFiltersHeight, setCurrentFiltersHeight] = useState<number>(0);
  const { height } = useSize(filtersRef.current);

  // Ref to keep track of the banner container section
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const { position, top } = useStickyScroll(currentFiltersHeight, bannerRef.current);
  const [currentTopPos, setCurrentTopPos] = useState<{
    position: 'fixed' | 'relative';
    top: string;
  }>({
    position: 'relative',
    top: 'initial',
  });

  // Component internal state
  const [displayMode, setDisplayMode] = useState<'grid' | 'list'>('grid');
  const [displayFilterOptions, setDisplayFilterOptions] = useState(false);
  const { isAdmin } = useContext(AuthCtx);
  const { setProjects } = useContext(BYDataCtx);
  const [selectedProjectType, setSelectedProjectType] = useState<ProjectType>('all');

  const { width } = useWindowSize();
  const shouldResize = width ? width < 1000 : false;

  useEffect(() => {
    setCurrentFiltersHeight(height);
  }, [height]);

  useEffect(() => {
    setCurrentTopPos({
      position: position,
      top: top,
    });
  }, [position, top]);

  const handleProjectClick =
    (projectType: ProjectType, setProjects: (projects: ShowcaseProject[]) => void) => async () => {
      try {
        setSelectedProjectType(projectType);
        const projects = await getShowcaseProjects();
        if (projectType === 'all') {
          setProjects(projects);
          setSelectedProjectType('all');
        } else {
          const filteredProjects = projects.filter((project: ShowcaseProject) => {
            if (project.settings?.project_categories) {
              return project.settings.project_categories.some(
                category => category.toLowerCase() === projectType.toLowerCase(),
              );
            }
            return false;
          });

          setProjects(filteredProjects);
        }
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <>
      <div
        ref={bannerRef}
        style={{
          zIndex: 10,
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '80vh',
          maxHeight: `700px`,
          position: currentTopPos.position,
          top: currentTopPos.top,
        }}
      >
        <ShowcaseFixedNavigationBar />
        <Stack
          sx={{
            zIndex: 15,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            p: shouldResize ? 2 : 15,
          }}
        >
          <Kicker
            sx={{
              color: '#000',
            }}
          >
            Berylls Digital Ventures
          </Kicker>
          <BigTitle
            sx={{
              fontSize: shouldResize ? '42px' : '96px',
              color: '#000',
              maxWidth: '700px',
              lineHeight: shouldResize ? '46px' : '96px',
              textAlign: 'center',
            }}
          >
            We digitize automobility.
          </BigTitle>
        </Stack>
        <Stack
          ref={filtersRef}
          sx={{
            display: 'flex',
            flexDirection: shouldResize ? 'column' : 'row',
            height: shouldResize ? undefined : `${STICKY_SCROLL_OFFSET}px`,
            zIndex: 15,
            alignItems: shouldResize ? undefined : 'center',
            py: shouldResize && displayFilterOptions ? 1 : shouldResize ? 2 : undefined,
          }}
        >
          {shouldResize && (
            <Stack
              direction={'row'}
              spacing={1}
              sx={{
                justifyContent: 'space-between',
                px: 2,
              }}
            >
              <ShowcaseHeaderDisplayModeButton
                displayMode={displayMode}
                onClick={() => {
                  setDisplayFilterOptions(!displayFilterOptions);
                  setTimeout(() => {
                    // Check if the filters ref is defined
                    if (filtersRef.current && bannerRef.current) {
                      // Get height of the filters section
                      const bannerHeight = bannerRef.current.getBoundingClientRect().height;
                      const filtersHeight = filtersRef.current.getBoundingClientRect().height;
                      setCurrentFiltersHeight(filtersHeight);
                      if (window.scrollY > bannerHeight - filtersHeight) {
                        setCurrentTopPos({
                          position: 'fixed',
                          top: `${-bannerHeight + filtersHeight}px`,
                        });
                      } else {
                        setCurrentTopPos({
                          position: 'relative',
                          top: 'initial',
                        });
                      }
                    }
                  }, 100);
                }}
              >
                Filters
              </ShowcaseHeaderDisplayModeButton>
              <ShowcaseHeaderShareButton projects={projectsToShare} onClick={onShareButtonClick}>
                Teilen
              </ShowcaseHeaderShareButton>
            </Stack>
          )}
          {headerOptionsVisible && (
            <Stack
              direction={'row'}
              sx={{
                flex: 1,
                px: 2,
              }}
            >
              <Stack
                direction={'row'}
                sx={{
                  margin: shouldResize ? undefined : 'auto',
                  maxWidth: shouldResize ? 'unset' : '1200px',
                  flex: 1,
                  display: !shouldResize ? undefined : displayFilterOptions ? 'inline' : 'none',
                  lineHeight: shouldResize ? '44px' : undefined,
                  pt: 1,
                }}
                spacing={shouldResize ? undefined : 1}
              >
                <ShowcaseHeaderButton
                  shouldBeInlineBlock={shouldResize}
                  onClick={handleProjectClick('all', setProjects)}
                  selected={selectedProjectType === 'all'}
                >
                  All
                </ShowcaseHeaderButton>
                <ShowcaseHeaderButton
                  shouldBeInlineBlock={shouldResize}
                  onClick={handleProjectClick('elyvate suite', setProjects)}
                  selected={selectedProjectType === 'elyvate suite'}
                >
                  Elyvate Suite
                </ShowcaseHeaderButton>
                <ShowcaseHeaderButton
                  shouldBeInlineBlock={shouldResize}
                  onClick={handleProjectClick('dashboards', setProjects)}
                  selected={selectedProjectType === 'dashboards'}
                >
                  Dashboards
                </ShowcaseHeaderButton>
                <ShowcaseHeaderButton
                  shouldBeInlineBlock={shouldResize}
                  onClick={handleProjectClick('konfiguratoren', setProjects)}
                  selected={selectedProjectType === 'konfiguratoren'}
                >
                  Konfiguratoren
                </ShowcaseHeaderButton>
                <ShowcaseHeaderButton
                  shouldBeInlineBlock={shouldResize}
                  isLastItem={true}
                  onClick={handleProjectClick('oem-software', setProjects)}
                  selected={selectedProjectType === 'oem-software'}
                >
                  OEM-Software
                </ShowcaseHeaderButton>

                {shouldResize
                  ? null
                  : [
                      <div style={{ flex: 1 }} />,
                      <ShowcaseHeaderDisplayModeButton
                        displayMode={displayMode}
                        onClick={() => {
                          setDisplayMode(displayMode === 'grid' ? 'list' : 'grid');
                          setGridOrListView(displayMode === 'grid' ? 'list' : 'grid');
                        }}
                      >
                        {displayMode === 'grid' ? 'List View' : 'Grid View'}
                      </ShowcaseHeaderDisplayModeButton>,
                      <ShowcaseHeaderShareButton projects={projectsToShare} onClick={onShareButtonClick}>
                        Teilen
                      </ShowcaseHeaderShareButton>,
                    ]}
              </Stack>
            </Stack>
          )}
        </Stack>
        <BlurredBannerBackground />
      </div>
      <div
        style={{
          width: '100vw',
          // minHeight: `${objectHeight + STICKY_SCROLL_OFFSET}px`,
          minHeight: `calc(80svh)`,
          maxHeight: `700px`,
          display: currentTopPos.position === 'fixed' ? 'block' : 'none',
        }}
      />
    </>
  );
};

export default ShowcaseMainHeaderSection;

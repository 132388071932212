export interface CaseCode {
  code: string;
  name: string;
}

export interface CalendarWeek {
  id: number;
  month: string;
  days: number;
}

export interface SystemCustomClaims {
  division?: string;
  isAdmin: boolean;
  permissions?: string;
}

export interface SystemFirebaseUser {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  photoURL?: string;
  phoneNumber?: string;
  disabled: boolean;
  customClaims?: SystemCustomClaims;
}

export type BYProject = {
  casecode: string;
  name: string;
  customer: string;
  color?: string;
};

export interface BYStaffingEntry {
  uid: string;
  year: number;
  cw: number;
  casecode: string;
  storyPoints: number;
  comments?: string;
}

export type Props = {
  children?: React.ReactNode;
};

export type ShowcaseAppFeature = {
  name: string;
  description: string;
  source?: string;
};

export type ShowcaseAppQuote = {
  quote_title: string;
  quote_description: string;
  author_name?: string;
  author_title?: string;
};

export type ShowcaseAppAboutUs = {
  name: string;
  description: string;
};

export type ShowcaseAppInDepthFeature = {
  name: string;
  description: string;
};

export type ShowcaseAppContactPerson = {
  name: string;
  title: string;
  email: string;
};

export type ShowcaseAppTextImage = {
  kicker_text: string;
  title: string;
  description: string;
};

export type ShowcaseApp = {
  name: string;
  project_id: string;

  // Images
  mainAppScreenshotImage: string[];

  // Showcase Header Section
  headerKickerText: string;
  headerTitle: string;
  headerDescription: string;

  features: ShowcaseAppFeature[];

  // Showcase Accordion Section
  accordionKickerText: string;
  accordionTitle: string;
  accordionDescription: string;
  accordionFeatures: ShowcaseAppFeature[];

  // Feature Overview Section
  featureOverviewKickerText: string;
  featureOverviewTitle: string;

  // InDepth Feature Section
  inDepthFeatureKickerText: string;
  inDepthFeatureImage: string;
  inDepthFeatureMainHeading: ShowcaseAppInDepthFeature;
  inDepthFeatureSubHeading: ShowcaseAppInDepthFeature;
  inDepthFeatureSectionHeading: ShowcaseAppInDepthFeature;

  // Text Image Section/ Image Text Section
  textImage: ShowcaseAppTextImage[];

  // Quotes
  quote: ShowcaseAppQuote;
  featureQuote: ShowcaseAppQuote;

  // Text Block
  textBlockTitle: string;
  textBlockDescription: string;

  // Video or Image + Features

  videoOrImageWithFeatureKickerText: string;
  videoOrImageWithFeatureTitle: string;

  // Animation Section
  animationKickerText: string;
  animationTitle: string;
  animationDescription: string;
  animationSource: string;

  // About Us
  aboutUsTitle: string;
  aboutUsItems: ShowcaseAppAboutUs[];

  // Contact
  contactPerson: ShowcaseAppContactPerson;
};

export type ShowcaseHeader = {
  __component: string;
  kickerText: string;
  title: string;
  description: string;
  showComponent: boolean;
  feature: showCaseFeature[];
  image: ShowcaseImage;
};

export type ShowcaseSettings = {
  project_color: string;
  project_categories: string[];
  project_main_category: string;
  demo_url?: string | null;
};

export type ShowcaseAnimation = {
  __component: string;
  kickerText: string;
  title: string;
  description: string;
  showComponent: boolean;
  enableAnimation: boolean;
  media: {
    image: {
      data: {
        attributes: {
          url: string;
          ext?: string;
        };
      };
    };
  };
};

export type showCaseFeature = {
  name: string;
  description: string;
  icon?: ShowcaseImage;
};

export type ShowcaseFeatureOverview = {
  __component: string;
  kickerText: string;
  title: string;
  showComponent: boolean;
  feature: showCaseFeature[];
};
type textAndImage = {
  kickerText: string;
  title: string;
  description: string;
  image: ShowcaseImage;
  rightImagePosition: boolean;
};
export type ShowcaseTextImage = {
  __component: string;
  textAndImage: textAndImage;
  showComponent: boolean;
};

export type ShowcaseQuote = {
  __component: string;
  authorName: string;
  authorTitle: string;
  description: string;
  title: string;
  showComponent: boolean;
};

export type ShowcaseFeatureQuote = {
  __component: string;
  title: string;
  description: string;
  showComponent: boolean;
};

export type ShowcaseVideoOrImageWithFeatures = {
  __component: string;
  kickerText: string;
  title: string;
  showComponent: boolean;
  feature: showCaseFeature[];
  media: {
    image: ShowcaseImage;
  };
};

export type ShowcaseAccordion = {
  __component: string;
  kickerText: string;
  title: string;
  description: string;
  showComponent: boolean;
  featureWithImage: { name: string; description: string; image: ShowcaseImage }[];
};

export type ShowcaseTextBlock = {
  __component: string;
  title: string;
  description: string;
  showComponent: boolean;
};

export type ShowcaseInDepthDescription = {
  __component: string;
  kickerText: string;
  showComponent: boolean;
  feature?: showCaseFeature[];
  mainHeading?: { name: string; description: string };
  subHeading?: { name: string; description: string };
  sectionHeading?: { name: string; description: string };
  legend?: string;
  media?: {
    image: {
      data: {
        attributes: {
          url: string;
          ext?: string;
        };
      };
    };
  };
};

export type ShowcaseContact = {
  data: {
    attributes: {
      name: string;
      title: string;
      email: string;
      image: ShowcaseImage;
      showComponent: boolean;
    };
  };
};

type ShowcaseImage = {
  data: {
    attributes: {
      url: string;
      ext?: string;
    };
  };
};

export type ShowcaseProject = {
  name: string;
  project_id: string;
  mainAppScreenshotImage: ShowcaseImage;
  header: ShowcaseHeader | null;
  dynamicField: (
    | ShowcaseAnimation
    | ShowcaseFeatureOverview
    | ShowcaseTextImage
    | ShowcaseQuote
    | ShowcaseFeatureQuote
    | ShowcaseVideoOrImageWithFeatures
    | ShowcaseAccordion
    | ShowcaseTextBlock
    | ShowcaseInDepthDescription
  )[];

  contact: ShowcaseContact | null;
  settings: ShowcaseSettings | null;
};

export enum ShowcaseUserPageViewType {
  ADMIN_OVERVIEW = 'ADMIN_OVERVIEW',
  ADMIN_DETAIL_VIEW = 'ADMIN_DETAIL_VIEW',
  CLIENT_OVERVIEW = 'CLIENT_OVERVIEW',
  CLIENT_DETAIL_VIEW = 'CLIENT_DETAIL_VIEW',
}

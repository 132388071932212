import React, { createContext } from 'react';
import { getAuth, ParsedToken, User } from 'firebase/auth';

// --------------------------------------------------------
// Context to hold Authenticated user.

interface IAuthStateProps {
  user: User | null;
  loading: boolean;
  isAdmin: boolean;
  permissions: string[];
}

export const AuthCtx = createContext({} as IAuthStateProps);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<User | null>(null);
  const [permissions, setPermissions] = React.useState<string[]>([]);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [loadingAuth, setLoadingAuth] = React.useState<boolean>(true);

  React.useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        const idTokenResult = await auth.currentUser!.getIdTokenResult(true);
        const claims = idTokenResult.claims;
        if (claims['permissions']) {
          const permissions = claims['permissions'] as string | undefined;
          if (permissions) {
            setPermissions(permissions.split(','));
          }
        }
        if (claims['isAdmin']) {
          const userIsAdmin = claims['isAdmin'] as boolean | undefined;
          if (userIsAdmin) {
            setIsAdmin(userIsAdmin);
          }
        }
      }
      setUser(firebaseUser);
      setLoadingAuth(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthCtx.Provider
      value={{
        user: user,
        loading: loadingAuth,
        isAdmin: isAdmin,
        permissions: permissions,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

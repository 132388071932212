// MUI
import { InputBase, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Copy, CopyBold, CopySmall, CopySmallBold } from '../TypographyFonts';
import { FixedColors } from 'theme';
import ShareDialogButton from './ShareDialogButton';

interface FormError {
  field: string;
  message: string;
}

interface Step1InviteeNameProps {
  onSubmitInviteeName: (inviteeName: string) => Promise<{ statusCode: number; message: string } | undefined>;
  errorMessage?: string;
}

export const Step1InviteeName: FC<Step1InviteeNameProps> = ({ onSubmitInviteeName, errorMessage }) => {
  const [inviteeName, setInviteeName] = useState('');
  const [errors, setErrors] = useState<FormError[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setErrors([{ field: 'Invitee-Name', message: errorMessage || '' }]);
    return;
  }, [errorMessage]);

  const handleInviteeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setInviteeName(event.target.value);
  };

  const handleSubmitInviteeName = async () => {
    if (inviteeName.trim() === '') {
      setErrors([{ field: 'Invitee-Name', message: 'This field is required.' }]);
      return;
    }
    if (inviteeName.length < 3) {
      setErrors([{ field: 'Invitee-Name', message: 'Length of the invitee name should be greater than 3.' }]);
      return;
    }
    // Assuming onSubmitInviteeName is an asynchronous function that returns a promise
    try {
      // Set loading to true before the async call
      setLoading(true);

      setTimeout(async () => {
        const response = await onSubmitInviteeName(inviteeName);
        if (response?.statusCode === 409) {
          setErrors([{ field: 'Invitee-Name', message: response.message }]);
          return;
        }
        setLoading(false);
      }, 500);
      // Set loading to false after the async call completes successfully
    } catch (error) {
      setLoading(false); // Set loading to false in case of an error during the async call
      // Handle the error (e.g., show an error message)
    }
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={'4px'}>
        <CopyBold>Name your invitation</CopyBold>
        <Copy>
          Please provide a name for the invitation so that we can keep track of who has access to our work. Please keep
          in mind that all links are only valid for 30 days.
        </Copy>
      </Stack>

      <Stack spacing={'11px'}>
        <CopySmallBold>Name of invitee</CopySmallBold>
        <InputBase
          sx={{
            mb: errors.some(error => error.field === 'Invitee-Name') ? 0 : 2,
            borderRadius: '8px',
            // https://www.w3docs.com/snippets/css/how-to-auto-hide-placeholder-text-on-focus-with-css-and-jquery.html
            // Trick to hide placeholder text when input is focused
            fontSize: '0.8rem',
            py: '4px',
            pl: 2,
            border: `1px solid ${FixedColors.almostBlack}`,
            '& .placeholder': {
              color: FixedColors.almostBlack,
              fontFamily: 'NeueFaktum-Regular',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '20px',
              lineHeight: '28px',
            },
            height: '60px',
            // on type update the error message
          }}
          inputProps={{ className: 'placeholder' }}
          value={inviteeName}
          onChange={handleInviteeInputChange}
          error={errors.some(error => error.field === 'Invitee-Name')}
        />
      </Stack>
      {errors.map(error =>
        error.field === 'Invitee-Name' ? (
          <Typography key={error.field} variant="body2" color="error">
            {error.message}
          </Typography>
        ) : null,
      )}
      <ShareDialogButton onClick={handleSubmitInviteeName} loading={loading}>
        Generate Password
      </ShareDialogButton>
    </Stack>
  );
};

export default Step1InviteeName;
